import { axiosCustom } from "./axiosCustom";

export const getResourcesByLearningPathwayId = async ({ userId, pathwayId }) => {
    try {
        const response = await axiosCustom.get(`/pathways/${pathwayId}/blocks?user_id=${userId}`);
        return response.data.blocks;
    } catch (error) {
        console.error('Error fetching resource blocks:', error);
        throw error;
    }
};

export const uploadLinkResource = async ({ userId, content: { title, description, url, type, pathwayId } }) => {
    console.log({ userId, title, description, url, type, pathwayId });
    try {
        const response = await axiosCustom.post(`/link_blocks?user_id=${userId}`, {
            "custom_title": title || "",
            "custom_description": description || "",
            "url": url,
            "content_type": type || "article", // article, video, audio, book, other //
            "pathway_id": pathwayId
        });
        return response.data;
    } catch (error) {
        console.error("Error posting URL resource:", error);
        throw error;
    }
};


export const uploadTextResource = async ({ userId, content: { title, content, pathwayId } }) => {
    console.log({ userId, title, content, pathwayId });
    try {
        const response = await axiosCustom.post(`/txt_blocks?user_id=${userId}`, { title, content, pathway_id: pathwayId });
        return response.data;
    } catch (error) {
        console.error("Error posting text resource:", error);
        throw error;
    }
};


export const uploadPdfResource = async (formData) => {
    try {
        const userId = formData.get('userId');
        const response = await axiosCustom.post(`/pdf_uploads?user_id=${userId}`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        return response.data;
    } catch (error) {
        console.error("Error uploading a pdf resource:", error);
        throw error;
    }
};




export const updateLinkBlock = async ({ id, userId, content: { title, description, thumbnail_url, icon_url, content_type } }) => {
    try {
        const response = await axiosCustom.put(`/link_blocks/${id}?user_id=${userId}`, { title, description, thumbnail_url, icon_url, content_type });
        return response.data;
    } catch (error) {
        console.error('Error updating link block:', error);
        throw error;
    }
};
export const updateTextBlock = async ({ id, userId, content: { title, content, pathway_id } }) => {
    try {
        const response = await axiosCustom.put(`/txt_blocks/${id}?user_id=${userId}`, { title, content, pathway_id: +pathway_id });
        return response.data;
    } catch (error) {
        console.error('Error updating text block:', error);
        throw error;
    }
};
export const updatePdfBlock = async ({ id, userId, content: { title, description } }) => {
    try {
        const response = await axiosCustom.put(`/pdf_uploads/${id}?user_id=${userId}`, { title, description });
        return response.data;
    } catch (error) {
        console.error('Error updating pdf block:', error);
        throw error;
    }
};
export const updateCommunityBlock = async ({ id, userId, content: { name, description } }) => {
    try {
        const response = await axiosCustom.put(`/provinces/${id}?user_id=${userId}`, { name, description });
        return response.data;
    } catch (error) {
        console.error('Error updating pathway block:', error);
        throw error;
    }
};
export const updatePathwayBlock = async ({ id, userId, content: { name, description, province_id, city_id } }) => {
    try {
        const response = await axiosCustom.put(`/pathways/${id}?user_id=${userId}`, { name, description, province_id, city_id });
        return response.data;
    } catch (error) {
        console.error('Error updating pathway block:', error);
        throw error;
    }
};

export const deactivateLinkBlock = async ({ id, userId }) => {
    try {
        const response = await axiosCustom.patch(`/link_blocks/deactivate/${id}?user_id=${userId}`);
        return response.data;
    } catch (error) {
        console.error('Error deactivating link block:', error);
        throw error;
    }
};
export const deactivateTextBlock = async ({ id, userId }) => {
    try {
        const response = await axiosCustom.patch(`/txt_blocks/deactivate/${id}?user_id=${userId}`);
        return response.data;
    } catch (error) {
        console.error('Error deactivating text block:', error);
        throw error;
    }
};
export const deactivatePdfBlock = async ({ id, userId }) => {
    try {
        const response = await axiosCustom.delete(`/pdf_uploads/deactivate_pdf_upload/${id}?user_id=${userId}`);
        return response.data;
    } catch (error) {
        console.error('Error deactivating pdf block:', error);
        throw error;
    }
};
export const deactivatePathwayBlock = async ({ id, userId }) => {
    try {
        const response = await axiosCustom.delete(`/pathways/${id}?user_id=${userId}`);
        return response.data;
    } catch (error) {
        console.error('Error deactivating pathway block:', error);
        throw error;
    }
};