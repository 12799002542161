import React from 'react';
import './SkeletonLoaders.scss';

export function CommunitySkeletonLoaderPage(props) {
    return (
        <div className={"community-skeleton-loader-page-container"}>
            <CommunitySkeletonLoader page={props.page || ""} />
        </div>
    );
}


function CommunitySkeletonLoader(props) {
    return (
        <div className={"community-skeleton-loader-container"}>
            <div className={`title-container ${props.page}`} >
                <div className='title fill'></div>
                {props.page === "home" &&
                    <div className='tag fill'></div>
                }
            </div>
            <PathwaySeletonLoader />
            <PathwaySeletonLoader />
        </div>
    );
}

function PathwaySeletonLoader() {
    return (
        <div className='pathway-skeleton-loader-container'>
            <div className='path fill'></div>
            <div className='desc-container'>
                <span className='line'></span>
                <span className='desc fill'></span>
            </div>
            <div className='block l fill'></div>
            <div className='block m fill'></div>
            <div className='block s fill'></div>
        </div>
    );
}