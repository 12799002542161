import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { listAllLearningPathways, listAllMyLearningPathways, listAllRecommendedLearningPathways } from "../api/learningPathway";
import { asyncFetchResources } from "./resourcesSlice";

const initialState = {
    pathwaysData: {
        allData: {},
        list: {},
        myList: {},
        recommendedList: [],
    },
    status: {
        allData: "idle",
        list: "idle",
        myList: "idle",
        recommendedList: "idle"
    },
    error: {
        allData: null,
        list: null,
        myList: null,
        recommendedList: null
    },
};


export const asyncFetchPathways = createAsyncThunk("pathways/fetchPathways", async (communityIds, { dispatch }) => {
    let allPathways = {};
    for (const communityId of communityIds) {
        const learningPathways = await listAllLearningPathways(communityId);
        if (learningPathways.length > 0) {
            learningPathways.forEach(path => {
                allPathways[path.pathway_id] = path;
            });
        }
    }
    return allPathways;
});

export const asyncFetchMyPathways = createAsyncThunk("pathways/fetchMyPathways", async (userId) => {
    let allPathways = {};
    const learningPathways = await listAllMyLearningPathways(userId);
    if (learningPathways.length > 0) {
        learningPathways.forEach(path => {
            allPathways[path.pathway.pathway_id] = path.pathway;
        });
    }
    return allPathways;
});

export const asyncFetchRecommendedPathways = createAsyncThunk("pathways/fetchRecommendedPathways", async (userId) => {
    // let allPathways = {};
    const learningPathways = await listAllRecommendedLearningPathways(userId);
    // if (learningPathways.length > 0) {
    //     learningPathways.forEach(path => {
    //         allPathways[path.pathway.pathway_id] = path.pathway;
    //     });
    // }
    return learningPathways;
    // return allPathways;
});

export const asyncFetchPathwaysAndResources = createAsyncThunk("pathways/fetchPathwaysAndResources", async ({ communityId, userId, num }, { dispatch }) => {
    const learningPathways = await listAllLearningPathways(communityId);
    let pathwaysData = {};
    let count = 0;
    for (const pathway of learningPathways) {
        if (count >= num) break;
        const resourcesResponse = await dispatch(asyncFetchResources({
            userId: userId,
            pathwayId: pathway.pathway_id,
        }));
        if (asyncFetchResources.fulfilled.match(resourcesResponse)) {
            pathwaysData[pathway.pathway_id] = {
                id: pathway.pathway_id,
                title: pathway.name,
                description: pathway.description,
                data: resourcesResponse.payload.blocks && resourcesResponse.payload.blocks
            };
            console.log(resourcesResponse.payload);
        } else {
            pathwaysData[pathway.pathway_id] = {
                id: pathway.pathway_id,
                title: pathway.name,
                description: pathway.description,
                data: []
            };
        }
        count++;
    }
    return pathwaysData;
});



export const pathwaysSlice = createSlice({
    name: "pathways",
    initialState,
    reducers: {
        setPathwaysAndResources: (state, action) => {
            state.pathwaysData.allData = action.payload;
        },
        setPathwaysAndResourcesStatus: (state, action) => {
            state.status.allData = action.payload;
        },
        setPathways: (state, action) => {
            state.pathwaysData.list = action.payload;
        },
        setMyPathways: (state, action) => {
            state.pathwaysData.myList = action.payload;
        },
        setPathwaysStatus: (state, action) => {
            state.status.list = action.payload;
        },
        removePathway: (state, action) => {
            const pathwayId = action.payload;
            delete state.pathwaysData.myList[pathwayId];
        },
        clearMyPathway: (state) => {
            state.pathwaysData.myList = {};
            state.status.myList = "idle";
            state.error.myList = null;
        },
        clearPathways: (state) => {
            state.pathwaysData.list = {};
            state.status.list = "idle";
            state.error.list = null;
        }
    },
    extraReducers(builder) {
        builder
            .addCase(asyncFetchPathwaysAndResources.pending, (state) => {
                state.status.allData = 'loading';
            })
            .addCase(asyncFetchPathwaysAndResources.fulfilled, (state, action) => {
                state.status.allData = 'succeeded';
                state.pathwaysData.allData = action.payload;
            })
            .addCase(asyncFetchPathwaysAndResources.rejected, (state, action) => {
                state.status.allData = "failed";
                state.error.allData = action.error.message;
            })
            .addCase(asyncFetchPathways.pending, (state) => {
                state.status.list = 'loading';
            })
            .addCase(asyncFetchPathways.fulfilled, (state, action) => {
                state.status.list = 'succeeded';
                state.pathwaysData.list = action.payload;
            })
            .addCase(asyncFetchPathways.rejected, (state, action) => {
                state.status.list = "failed";
                state.error.list = action.error.message;
            })
            .addCase(asyncFetchMyPathways.pending, (state) => {
                state.status.myList = 'loading';
            })
            .addCase(asyncFetchMyPathways.fulfilled, (state, action) => {
                state.status.myList = 'succeeded';
                state.pathwaysData.myList = action.payload;
            })
            .addCase(asyncFetchMyPathways.rejected, (state, action) => {
                state.status.myList = "failed";
                state.error.myList = action.error.message;
            })
            .addCase(asyncFetchRecommendedPathways.pending, (state) => {
                state.status.recommendedList = 'loading';
            })
            .addCase(asyncFetchRecommendedPathways.fulfilled, (state, action) => {
                state.status.recommendedList = 'succeeded';
                state.pathwaysData.recommendedList = action.payload;
            })
            .addCase(asyncFetchRecommendedPathways.rejected, (state, action) => {
                state.status.recommendedList = "failed";
                state.error.recommendedList = action.error.message;
            });
    }
});



export const selectAllPathwaysAndResources = (state) => state.pathways.pathwaysData.allData;
export const selectAllPathways = (state) => state.pathways.pathwaysData.list;
export const selectAllMyPathways = (state) => state.pathways.pathwaysData.myList;
export const selectAllRecommendedPathways = (state) => state.pathways.pathwaysData.recommendedList;

export const selectAllPathwaysAndResourcesStatus = (state) => state.pathways.status.allData;
export const selectAllPathwaysStatus = (state) => state.pathways.status.list;
export const selectAllMyPathwaysStatus = (state) => state.pathways.status.myList;
export const selectAllRecommendedPathwaysStatus = (state) => state.pathways.status.recommendedList;


export const { setPathwaysAndResources, setPathwaysAndResourcesStatus, setPathways, setPathwaysStatus, removePathway, setMyPathways, clearMyPathway, clearPathways } = pathwaysSlice.actions;
export default pathwaysSlice.reducer;