import React, { useContext, useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import './CommunityPage.scss';
import { Helmet } from 'react-helmet';
import { GlobalContext } from '../../context/globalContext';
import { useDispatch, useSelector } from 'react-redux';
import { asyncFetchMyPathways, asyncFetchPathways, clearMyPathway, clearPathways, selectAllMyPathways, selectAllPathways, selectAllPathwaysStatus } from '../../features/pathwaysSlice';
import { CommunitySkeletonLoaderPage } from '../../components/Loaders/SkeletonLoaders/SkeletonLoaders';
import { selectCurrentUser } from '../../features/userSlice';
import { FormInput } from '../../components/Form/Form';
import PathwayBlock from '../../components/Blocks/PathwayBlock';
import { performSearch } from '../../api/search';
import { createLearningPathway } from '../../api/learningPathway';
import Success from '../../components/Notification/Success';
import { isGuest } from '../../util/userLoggedIn';
import { LoginFirstBlock } from '../../components/PleaseLogin/PleaseLogin';

export default function CommunityPage() {
    const location = useLocation();
    const dispatch = useDispatch();

    const queryParams = new URLSearchParams(location.search);
    const communityId = decodeURIComponent(queryParams.get('id'));
    const communityName = decodeURIComponent(queryParams.get('name'));
    const { allCommunities } = useContext(GlobalContext);

    const pathwayList = useSelector(selectAllPathways);
    const pathwayListStatus = useSelector(selectAllPathwaysStatus);
    const user = useSelector(selectCurrentUser);
    const [query, setQuery] = useState("");
    const [results, setResults] = useState(null);
    const [postIsOpen, setPostIsOpen] = useState(false);
    const pathwayMyList = useSelector(selectAllMyPathways);

    const [postSuccess, setPostSuccess] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    const [newPathway, setNewPathway] = useState({
        name: "", desc: ""
    });

    async function handleSearch(e) {
        e.preventDefault();
        if (user && user.userId && query) {
            const result = await performSearch({
                userId: user.userId,
                content: {
                    "query": query,
                    "includeCommunities": false,
                    "includePathways": true,
                    "includeCities": false,
                    "includeLinkBlocks": false,
                    "includeTxtBlocks": false,
                    "includePdfUploads": false,
                    "communityId": +communityId,
                    "k": 10
                }
            });
            setResults(result);
        }
    }

    async function handleCreatePathway(e) {
        e.preventDefault();
        if (user && user.userId && communityId) {
            const response = await createLearningPathway({
                userId: user.userId,
                content: {
                    title: newPathway.name,
                    description: newPathway.desc,
                    communityId: communityId,
                }
            });

            if (response.pathway_id > 0) {
                setPostIsOpen(false);
                setPostSuccess(true);
                setNewPathway({ name: "", desc: "" });
                dispatch(asyncFetchPathways([+communityId]));
                dispatch(asyncFetchMyPathways(user.userId));
            }
        }

    }

    useEffect(() => {
        setIsLoading(true);
        if (communityId && user && user.userId) {
            const fetchPathways = dispatch(asyncFetchPathways([communityId]));
            const fetchMyPathways = dispatch(asyncFetchMyPathways(user.userId));
            Promise.all([fetchPathways, fetchMyPathways]).then(() => setIsLoading(false));
        }

    }, [dispatch, user, communityId]);

    useEffect(() => {
        return () => {
            dispatch(clearPathways());
            dispatch(clearMyPathway());
        };
    }, [dispatch]);


    useEffect(() => {
        dispatch(clearPathways());
        dispatch(clearMyPathway());
    }, [dispatch, location]);

    if (isLoading || pathwayListStatus === "idle" || pathwayListStatus === "loading") {
        return (<CommunitySkeletonLoaderPage />);
    }

    return (
        <>
            <Helmet>
                <title>{`${communityName} | Tecademia`}</title>
                <meta name='description' content='' />
                <meta name='keywords' content='' />
            </Helmet>
            {
                pathwayListStatus === "succeeded" &&
                <div className='learning-pathway-page-container'>
                    {/* {pathwayId && pathwayMyList && pathwayMyList[pathwayId] && pathway &&
                        <ActionBlock
                            type="pathway"
                            id={pathwayId}
                            setIsEditing={setIsEditing}
                            setIsDeleted={null}
                            community={{ id: pathway.province_id, name: communityName }}
                            restoreValue={() => { setValueDesc(pathwayDescription); setValueName(pathwayName); }}
                            content={{
                                name: valueName.trim(),
                                description: valueDesc.trim(),
                                province_id: pathway.province_id,
                                city_id: pathway.city_id,
                            }}
                        />} */}
                    {allCommunities && allCommunities[communityId] &&
                        <div>
                            <form onSubmit={handleSearch}>
                                <h3 className='title'>{allCommunities[communityId].name}</h3>
                                <FormInput
                                    class="query-input"
                                    type="text"
                                    value={query}
                                    update={(value) => {
                                        setQuery(value);
                                        if (value === '') {
                                            setResults(null);
                                        }
                                    }}
                                    placeholder={`search pathways in ${allCommunities[communityId].name} ...`}
                                />
                            </form>
                        </div>
                    }
                    <div className={"learning-pathway-container"}>
                        <h4>Pathways</h4>
                        <>
                            <div className='block-container inner add-container' >
                                {postSuccess && <Success message="Successfully created a pathway!" />}
                                <button className="post-btn" onClick={() => { setPostIsOpen(!postIsOpen); }}>
                                    <span className="material-symbols-outlined icon">{postIsOpen ? "remove" : "add"}</span>
                                    <span>create a pathway</span>
                                </button>
                                {postIsOpen &&
                                    <div className='post-container inner'>
                                        {/* <p className="cancel" onClick={() => { setPostIsOpen(false); document.querySelector("html").style.overflow = "visible"; }}>Cancel</p> */}
                                        {/* <h1 className='h-title'>Create a Pathway in {communityName}</h1> */}
                                        {isGuest(user) && <LoginFirstBlock />}
                                        <form onSubmit={handleCreatePathway} disabled={isGuest(user)}>
                                            <div className='flex-row'>
                                                <FormInput type="text" label="" placeholder="Name" update={(value) => { setNewPathway(pre => ({ ...pre, name: value })); }} value={newPathway.name} class="create-input" required={true} disabled={isGuest(user)} />
                                                <FormInput type="text" label="" placeholder="Description" update={(value) => { setNewPathway(pre => ({ ...pre, desc: value })); }} value={newPathway.desc} class="create-input" required={true} disabled={isGuest(user)} />
                                            </div>
                                            <button type='submit' className='upload-btn-inner c' disabled={isGuest(user)}>Create</button>
                                        </form>
                                        {/* <Upload pathwayId={pathwayId} pathwayName={pathwayId && pathwayList[pathwayId] && pathwayList[pathwayId].name} /> */}
                                    </div>
                                }
                            </div>
                        </>
                        {results && results.length > 0
                            ?
                            results.map(i => {

                                return (
                                    <PathwayBlock resource={{ cityId: i.document.city_id, communityId, id: i.document.pathway_id, name: i.document.name, description: i.document.description }} canDelete={pathwayMyList && pathwayMyList[i.document.pathway_id]} />
                                );
                            })
                            :
                            pathwayList && Object.keys(pathwayList).length > 0 && Object.keys(pathwayList).map((pathwayId, index) => {
                                const pathway = pathwayList[pathwayId];

                                if (!pathway) return null;
                                return (
                                    <PathwayBlock resource={{ cityId: pathway.city_id, communityId, id: pathwayId, name: pathway.name, description: pathway.description }} canDelete={pathwayMyList && pathwayMyList[pathwayId]} />
                                );
                            })
                        }
                    </div >
                </div>
            }
        </>
    );
}

