import React, { useEffect, useState } from 'react';
import './Blocks.scss';
import { ActionBlock, DeleteBlock, canClick } from './Blocks';
import { FormInput, FormTextarea } from '../Form/Form';


export default function LinkBlock(props) {
    const [isValid, setIsValid] = useState(false);
    const [isDeleted, setIsDeleted] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [values, setValues] = useState({
        title: props.resource.title,
        description: props.resource.description,
        thumbnailUrl: props.resource.thumbnail_url,
        iconUrl: props.resource.icon_url,
    });
    const [editThumbnailUrl, setEditThumbnailUrl] = useState(false);
    const [restoreValues, setRestoreValues] = useState(null);

    function isValidImage(url, callback) {
        let img = new Image();
        img.onload = function () { callback(true); };
        img.onerror = function () { callback(false); };
        img.src = url;
    }

    useEffect(() => {
        if (isEditing) {
            setRestoreValues(values);
        }
    }, [isEditing]);

    useEffect(() => {
        isValidImage(props.resource.thumbnail_url, setIsValid);
    }, [props.resource.thumbnail_url]);

    if (isDeleted) {
        return null;
    }

    return (
        <div className={`link-block-container block-container ${isValid && "with-img"}`} onClick={(e) => { if (canClick(e) && !isEditing) window.open(props.resource.url, '_blank'); }} key={props.resource.link_id}>
            {/* <a className={`link-block-container block-container ${isValid && "with-img"}`} href={props.resource.url} target='_blank' key={props.resource.link_id}> */}
            {isEditing &&
                <div className='img-url-edit-container'>
                    <span className="material-symbols-outlined icon" onClick={() => { setEditThumbnailUrl(!editThumbnailUrl); }} >edit_note</span>
                </div>
            }
            {isValid && <img src={values.thumbnailUrl} alt={values.title} />}
            <div className='content'>
                <div className='block-title-container'>
                    {props.resource.title && isEditing
                        ?
                        <FormInput class="icon-url-input" value={values.title} update={(value) => setValues(prev => ({ ...prev, title: value }))} required={true} />
                        : <p className='title'>{values.title}</p>
                    }
                    <div className='right'>
                        {props.canDelete &&
                            <ActionBlock
                                type="link"
                                id={props.resource.link_id}
                                setIsDeleted={setIsDeleted}
                                setIsEditing={setIsEditing}
                                restoreValue={() => { setValues(restoreValues); }}
                                content={{
                                    title: values.title && values.title.trim() || "",
                                    description: values.title && values.description.trim() || "",
                                    thumbnail_url: values.thumbnailUrl && values.thumbnailUrl.trim() || "",
                                    icon_url: props.resource.icon_url || "",
                                    content_type: "article"
                                }}
                            />
                        }
                        {/* {props.canDelete && <DeleteBlock type="link" id={props.resource.link_id} setIsDeleted={setIsDeleted} />} */}
                        <p className='tag link'>Link</p>
                    </div>
                </div>
                {isEditing && editThumbnailUrl && <FormInput class="thumbnail-url-input" value={values.thumbnailUrl} update={(value) => setValues(prev => ({ ...prev, thumbnailUrl: value }))} required={true} />}
                {props.resource.description && props.resource.description !== "N/A" && isEditing
                    ? <FormTextarea class="description-input" value={values.description} update={(value) => { setValues(prev => ({ ...prev, description: value })); }} />
                    : <p className='desc'>{values.description}</p>
                }
                <div className='icon-container'>
                    {(props.resource["icon_url"] && props.resource["icon_url"] !== "N/A") ? <img className="icon" src={props.resource["icon_url"]} alt="website icon" /> : <span className="material-symbols-outlined icon">public</span>}
                    <a className='url' href={props.resource.url} target='_blank'>{props.resource.url}</a>
                </div>
            </div>
        </div>
    );
}


