import React from 'react';
import './Banner.scss';
import { NavLink } from 'react-router-dom';

export default function Banner() {
    return (
        <div className={"banner-container"}>
            <NavLink to="/careers-challenge" target='_blank'>Win internships and prize money with the Tecademia <span>Careers Challenge 2024</span>!</NavLink>
        </div>
    );
}