import { axiosCustom } from "./axiosCustom";

// TODO: Test this! especially header auth
export const createCommunity = async ({ userId, content: { name, description } }) => {
    console.log(userId);
    console.log(name);
    console.log(description);
    try {
        const response = await axiosCustom.post(`/provinces?user_id=${userId}`, { name, description });
        return response.data;

    } catch (error) {
        console.error("Error creating community:", error);
        throw error;
    }
};

// TODO: test, should be fine
export const getCommunity = async (community_id) => {
    try {
        const response = await axiosCustom.get(`/provinces/${community_id}`);
        return response.data;
    } catch (error) {
        console.error("Error fetching community:", error);
        throw error;
    }
};

// TODO: Test this! especially header auth
export const updateCommunity = async ({ provinceId, userId, content: { name, description } }) => {
    try {
        const response = await axiosCustom.put(`/provinces/${provinceId}?user_id=${userId}`, { name, description });
        return response.data;
    } catch (error) {
        console.error("Error updating community:", error);
        throw error;
    }
};

// TODO: test, should be fine
export const getAllCommunities = async () => {
    try {
        const data = {};
        const response = await axiosCustom.get(`/provinces`);
        if (response.data) {
            response.data.forEach(i => {
                data[i.province_id] = i;
            });
        }
        return data;
    } catch (error) {
        console.error("Error fetching all communities:", error);
        throw error;
    }
};

export const deactivateCommunity = async ({ communityId, userId }) => {
    try {
        const response = await axiosCustom.put(`/provinces/deactivate/${communityId}?user_id${userId}`);
        return response.data;
    } catch (error) {
        console.error("Error deleting community:", error);
        throw error;
    }
};



