import React from 'react';
import './PrivacyPages.scss';
import { Helmet } from 'react-helmet';

export function TermsAndConditions() {
    return (
        <>
            <Helmet>
                <title>Terms & Conditions | Tecademia</title>
                <meta
                    name='description'
                    content=''
                />
                <meta
                    name='keywords'
                    content=''
                />
            </Helmet>
            <div className={"terms-and-conditions-container privacy-container"}>
                <h1>Tecademia Terms and Conditions</h1>
                <p>Last Updated: 08/11/2023</p>
                <h2>Acceptable Use:</h2>
                <p>Describes acceptable platform behaviours and responsibilities, prohibiting spam, disrespect, and copyright violations.</p>
                <h2>User Content:</h2>
                <p>Users must ensure that content is original, free of plagiarism, and they are responsible for its accuracy.</p>
                <h2>Intellectual Property:</h2>
                <p>Outlines that users agree to only post content for which they have the rights.</p>
                <h2>Amendments to Terms:</h2>
                <p>Terms may be updated, and users will be notified accordingly.</p>
            </div>
        </>
    );
}

export function PrivacyPolicy() {
    return (
        <>
            <Helmet>
                <title>Privacy Policy | Tecademia</title>
                <meta
                    name='description'
                    content=''
                />
                <meta
                    name='keywords'
                    content=''
                />
            </Helmet>
            <div className={"privacy-policy-container privacy-container"}>
                <h1>Tecademia Privacy Policy</h1>
                <p>Last Updated: 08/11/2023</p>
                <h2>Introduction:</h2>
                <p>Tecademia is a community-driven e-learning platform and talent marketplace designed to facilitate pre-employment training and connections between job seekers and employers.</p>
                <h2>Data Protection Officer (DPO):</h2>
                <p>The DPO for Tecademia is Richard Gong, CEO. For inquiries, please contact richard.gong@tecademia.com.</p>
                <h2>Information Collection and Use:</h2>
                <p>We collect names, Google accounts, educational profiles, and user-generated content for competition and talent matching purposes.</p>
                <h2>Legal Basis for Processing:</h2>
                <p>Processing is based on consent obtained at the time of registration and submission of information.</p>
                <h2>Data Sharing:</h2>
                <p>Data may be shared with potential employers for the purpose of talent searching and is protected under GDPR terms of agreement with these third parties.</p>
                <h2>Data Retention:</h2>
                <p>Data is retained until the user requests deletion or as necessary to provide our services.</p>
                <h2>User Rights:</h2>
                <p>Users have rights under GDPR, including data access, rectification, erasure, restriction, and portability. To exercise these rights, contact richard.gong@tecademia.com.</p>
                <h2>Data Security:</h2>
                <p>Data is stored securely on the Google Cloud Platform, with adherence to security standards.</p>
                <h2>International Data Transfer:</h2>
                <p>Transfers will comply with GDPR, details of which can be provided upon request.</p>
                <h2>Changes to This Policy:</h2>
                <p>Users will be notified of significant changes via email or platform notice.</p>

            </div>
        </>
    );
}

export function CookiesPolicy() {
    return (
        <>
            <Helmet>
                <title>Cookies Policy | Tecademia</title>
                <meta
                    name='description'
                    content=''
                />
                <meta
                    name='keywords'
                    content=''
                />
            </Helmet>
            <div className={"cookies-policy-container privacy-container"}>
                <h1>Tecademia Cookies Policy</h1>
                <p>Last Updated: 08/11/2023</p>
                <h2>Use of Cookies:</h2>
                <p>Tecademia uses cookies, including those set by Google Analytics, to enhance user experience and analyse service use.</p>
                <h2>Types of Cookies:</h2>
                <p>Details of specific cookies and their purposes are listed, with distinctions between essential and non-essential cookies.</p>
                <h2>Consent for Cookies:</h2>
                <p>Consent is obtained upon the first visit and managed according to user preferences.</p>
            </div>
        </>
    );
}