import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { asyncFetchPathways, asyncFetchPathwaysAndResources } from "./pathwaysSlice";
import { getAllCommunities } from "../api/communities";

const initialState = {
    communitiesData: {
        allData: {},
        list: {},
    },
    status: {
        allData: "idle",
        list: "idle",
    },
    error: {
        allData: null,
        list: null,
    },
};


export const asyncFetchAllCommunities = createAsyncThunk("communities/fetchAllCommunities", async () => {
    const communities = await getAllCommunities();
    return communities;
});

export const asyncFetchAllCommunitiesAndResources = createAsyncThunk("communities/fetchAllCommunitiesAndResources", async ({ userId, num }, { dispatch }) => {
    const communities = await getAllCommunities();
    let allData = {};

    let count = 0;
    for (const communityId of Object.keys(communities)) {
        if (count >= num) break;
        const communitiesResponse = await dispatch(asyncFetchPathwaysAndResources({ communityId, userId, count: 4 }));
        const community = communities[communityId];
        allData[communityId] = {
            id: community.province_id,
            title: community.name,
            description: community.description,
            data: asyncFetchPathwaysAndResources.fulfilled.match(communitiesResponse) ? communitiesResponse.payload : {}
        };
        count++;
    }
    return allData;
});




export const communitiesSlice = createSlice({
    name: "communities",
    initialState,
    reducers: {
        setCommunities: (state, action) => {
            state.communitiesData.list = action.payload;
        },
        setAddCommunities: (state, action) => {
            state.communitiesData.list = { ...state.communitiesData.list, ...action.payload };
        },
        setCommunitiesStatus: (state, action) => {
            state.status.list = action.payload;
        },
        setCommunitiesAndResources: (state, action) => {
            state.communitiesData.allData = action.payload;
        },
        setCommunitiesAndResourcesStatus: (state, action) => {
            state.status.allData = action.payload;
        },
    },
    extraReducers(builder) {
        builder
            .addCase(asyncFetchAllCommunitiesAndResources.pending, (state) => {
                state.status.allData = 'loading';
            })
            .addCase(asyncFetchAllCommunitiesAndResources.fulfilled, (state, action) => {
                state.status.allData = 'succeeded';
                state.communitiesData.allData = action.payload;
            })
            .addCase(asyncFetchAllCommunitiesAndResources.rejected, (state, action) => {
                state.status.allData = "failed";
                state.error = action.error.message;
            })
            .addCase(asyncFetchAllCommunities.pending, (state) => {
                state.status.list = 'loading';
            })
            .addCase(asyncFetchAllCommunities.fulfilled, (state, action) => {
                state.status.list = 'succeeded';
                state.communitiesData.list = action.payload;
            })
            .addCase(asyncFetchAllCommunities.rejected, (state, action) => {
                state.status.list = "failed";
                state.error = action.error.message;
            });

    }
});



export const selectAllCommunitiesAndResources = (state) => state.communities.communitiesData.allData;;
export const selectAllCommunitiesAndResourcesStatus = (state) => state.communities.status.allData;;
export const selectAllCommunities = (state) => state.communities.communitiesData.list;
export const selectAllCommunitiesStatus = (state) => state.communities.status.list;
export const selectAllCommunitiesError = (state) => state.communities.error.list;


export const { setCommunities, setCommunitiesStatus, setCommunitiesAndResources, setCommunitiesAndResourcesStatus, setAddCommunities } = communitiesSlice.actions;
export default communitiesSlice.reducer;