import React, { useEffect } from 'react';
import './ExploreCommunitiesPage.scss';
import { Helmet } from "react-helmet";
import Community from '../../components/Community/Community';
import { useDispatch, useSelector } from 'react-redux';
import { asyncFetchAllCommunities, selectAllCommunities, selectAllCommunitiesAndResources, selectAllCommunitiesAndResourcesStatus, selectAllCommunitiesStatus } from '../../features/communitiesSlice';
import { asyncFetchPathways, selectAllPathwaysAndResourcesStatus } from '../../features/pathwaysSlice';
import { CommunitySkeletonLoaderPage } from '../../components/Loaders/SkeletonLoaders/SkeletonLoaders';
import { selectCurrentUser } from '../../features/userSlice';
import CommunityBlock from '../../components/Blocks/CommunityBlock';

export default function ExploreCommunitiesPage() {
    const dispatch = useDispatch();
    const allResources = useSelector(selectAllCommunitiesAndResources);
    const allCommunities = useSelector(selectAllCommunities);
    const allResourcesStatus = useSelector(selectAllPathwaysAndResourcesStatus);
    const allCommunityStatus = useSelector(selectAllCommunitiesStatus);
    const user = useSelector(selectCurrentUser);

    useEffect(() => {
        dispatch(asyncFetchAllCommunities);
    }, [dispatch]);

    // useEffect(() => {
    //     if (user && user.userId) {
    //         dispatch(asyncFetchAllCommunitiesAndResources(user.userId));
    //     }
    // }, [dispatch, user]);

    return (
        <>
            <Helmet>
                <title>Tecademia</title>
                <meta
                    name="description"
                    content="Tecademia, a platform to note sharing"
                />
                <meta
                    name="keywords"
                    content="tecademia, noteshare, study, learning, tutorials"
                />
            </Helmet>
            {allCommunityStatus === "loading"
                ?
                <CommunitySkeletonLoaderPage page="home" />
                :
                allCommunityStatus === "succeeded" &&
                <div className={"home-page-container"}>
                    <h1 className='h-title title-center' style={{ fontSize: "26px" }}>Explore Communities</h1>
                    <div className='negative-margin'>
                        {allCommunities && Object.keys(allCommunities).map((i) => {
                            const community = allCommunities[i];
                            return (
                                <CommunityBlock resource={{ id: i, name: community.name, description: community.description }} />
                                // <Community community={{ id: i, name: community.name, description: community.description }} key={i} />
                            );
                        })}
                    </div>
                </div>
                // allResourcesStatus === "succeeded" &&
                // <div className={"home-page-container"}>
                //     {allResources && Object.keys(allResources).map(communityId => {
                //         return (
                //             <Community community={allResources[communityId]} key={communityId} />
                //         );
                //     })}
                // </div >
            }
        </>
    );
}

