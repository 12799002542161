import React from 'react';
import { NavLink } from 'react-router-dom';

export default function CommunityBlock(props) {
    return (
        <NavLink to={`/community?id=${props.resource.id}&name=${props.resource.name.replace(/ /g, '%20')}`} className='community-block-container block-container' key={props.resource.id}>
            <div className='block-title-container'>
                <p className='title flex'>
                    <span className="material-symbols-outlined icon">school</span>
                    {props.resource.name}
                </p>
                <p className='tag community'>Community</p>
            </div>
            <p>{props.resource.description}</p>
        </NavLink>
    );
}