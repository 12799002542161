import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { createUser, deactivateUser, fetchUser, fetchUserId, setUserAccess } from "../api/user";
import { GoogleAuthProvider, signInWithPopup, signOut, deleteUser as deleteFirebaseUser, onAuthStateChanged, setPersistence, browserLocalPersistence } from "firebase/auth";
import { auth } from "../config/firebaseConfig";
import { persistor, store } from "../app/store";
import { asyncFetchMyPathways, clearMyPathway } from "./pathwaysSlice";


const initialState = {
    userData: {
        userId: 1,
        displayName: "Guest",
        username: "Guest",
    },
    token: "",
    status: "idle",
    error: null,
    isUserIdFetched: false,
};

export const asyncSignInWithGoogle = createAsyncThunk('auth/signInWithGoogle', async (_, { dispatch }) => {
    const provider = new GoogleAuthProvider();
    await setPersistence(auth, browserLocalPersistence);
    const result = await signInWithPopup(auth, provider);
    return await fetchOrCreateUser(result.user, dispatch, false);
});

async function fetchOrCreateUser(user, dispatch) {
    try {
        // console.log(user);
        const idToken = await user.getIdToken(true);
        const r = await dispatch(setToken(idToken));
        if (r.type === "user/setToken/fulfilled") {
            const userIdResponse = await dispatch(asyncFetchUserId(user.uid));
            if (userIdResponse.payload && userIdResponse.payload[0]) {
                const fetchedUser = await dispatch(asyncFetchUser({
                    userId: userIdResponse.payload[1].user_id
                }));
                if (fetchedUser) {
                    user.userId = userIdResponse.payload[1].user_id;
                    await dispatch(asyncFetchMyPathways(user.userId));
                    return user;
                }
            } else if ((!userIdResponse.payload || !userIdResponse.payload[0])) {
                const createdUser = await dispatch(asyncCreateUser({
                    firebaseUid: user.uid,
                    username: user.username || user.displayName,
                    email: user.email
                }));
                user.userId = createdUser.payload.user_id;
                await dispatch(asyncFetchMyPathways(user.userId));
                return user;
            }
        }
    } catch (error) {
        await signOut(auth);
        console.error(error);
    }
}

export const setToken = createAsyncThunk('user/setToken', async (token, { dispatch, getState }) => {
    dispatch({ type: 'user/setTokenInState', payload: token });
    const { token: initialToken } = getState().user;
    if (initialToken === token && initialToken !== "") {
        return;
    }
    return new Promise((resolve) => {
        const unsubscribe = store.subscribe(() => {
            const { token: updatedToken } = getState().user;
            if (updatedToken === token || !updatedToken) {
                unsubscribe();
                resolve();
            }
        });
    });
});

export const asyncLoginWithGoogle = createAsyncThunk("auth/loginWithGoogle", async (user, { dispatch }) => {
    return await fetchOrCreateUser(user, dispatch, true);
});


export const asyncSignOut = createAsyncThunk('auth/signOut', async (_, { dispatch }) => {
    await signOut(auth);
    await clearPersistedState(dispatch);
    dispatch(clearMyPathway());
    window.location.href = "/communities";
    return { userId: 1, displayName: "Guest", username: "Guest" };

});

export const asyncFetchUserId = createAsyncThunk('auth/fetchUserId', async (firebaseId) => {
    const userId = await fetchUserId(firebaseId);
    return userId;
});

export const asyncFetchUser = createAsyncThunk("user/fetchUser", async ({ userId }) => {
    const user = await fetchUser({ userId });
    return user;
});

export const asyncCreateUser = createAsyncThunk("user/createUser", async (userInfo) => {
    const user = await createUser(userInfo);
    return user;
});

export const asyncDeleteUser = createAsyncThunk("user/deleteUser", async (user, { dispatch }) => {
    console.log("in");
    const currentUser = auth.currentUser;
    const userTemp = user;
    console.log(userTemp.userId);
    console.log(userTemp.token);
    await clearPersistedState(dispatch);
    // await deleteFirebaseUser(currentUser);
    const response = await deactivateUser({ userId: userTemp.userId });
    return response;
});

export async function clearPersistedState(dispatch) {
    await dispatch(setToken(""));
    await persistor.purge();
}



export const userSlice = createSlice({
    name: "user",
    initialState,
    reducers: {
        setUser: (state, action) => {
            state.userData = action.payload;
        },
        setUserStatus: (state, action) => {
            state.status = action.payload;
        },
        setUserIdFetched: (state, action) => {
            state.isUserIdFetched = action.payload;
        }
    },
    extraReducers(builder) {
        builder
            .addCase(asyncFetchUser.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(asyncFetchUser.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.userData = action.payload;
            })
            .addCase(asyncFetchUser.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.error.message;
            })
            .addCase(asyncCreateUser.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(asyncCreateUser.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.userData = action.payload;
            })
            .addCase(asyncCreateUser.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.error.message;
            })
            .addCase(asyncSignInWithGoogle.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(asyncSignInWithGoogle.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.userData = action.payload;
            })
            .addCase(asyncSignInWithGoogle.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.error.message;
            })
            .addCase(asyncSignOut.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(asyncSignOut.fulfilled, (state) => {
                state.status = 'succeeded';
                state.userData = {};
            })
            .addCase(asyncSignOut.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.error.message;
            })
            .addCase(asyncLoginWithGoogle.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(asyncLoginWithGoogle.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.isUserIdFetched = true;
                state.userData = action.payload;
            })
            .addCase(asyncLoginWithGoogle.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.error.message;
            })
            .addCase('user/setTokenInState', (state, action) => {
                state.token = action.payload;
            });
    }
});




export const selectCurrentUser = (state) => state.user.userData;
export const selectIsUserIdFetchedStatus = (state) => state.user.isUserIdFetched;


export const { setUser, setUserStatus, setUserIdFetched } = userSlice.actions;
export default userSlice.reducer;