import axios from "axios";
import { store } from "../app/store"; // import your Redux store

const baseUrl = "https://tecademia1-mvp6c-7ugv77wolq-uc.a.run.app/api/v1.6";

export const axiosCustom = axios.create({
    baseURL: baseUrl,
    withCredentials: true
});

axiosCustom.interceptors.request.use(
    config => {
        const { user } = store.getState();
        const token = user.token;
        // console.log(token);
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
            config.headers.Hello = `Bearer ${token}`;
        }

        return config;
    },
    error => Promise.reject(error)
);

export const axiosCustomNoApi = axios.create({
    baseURL: "https://tecademia1-mvp6c-7ugv77wolq-uc.a.run.app",
    withCredentials: true
});

axiosCustomNoApi.interceptors.request.use(
    config => {
        const { user } = store.getState();
        const token = user.token;

        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
            config.headers.Hello = `Bearer ${token}`;
        }

        return config;
    },
    error => Promise.reject(error)
);