import React, { useContext, useState, useEffect, useRef } from 'react';
import './Upload.scss';
import { Helmet } from 'react-helmet';
import { GlobalContext } from '../../context/globalContext';
import { useSelector, useDispatch } from "react-redux";
import { asyncFetchMyPathways, asyncFetchPathways, selectAllMyPathways, selectAllPathways, selectAllPathwaysStatus } from "../../features/pathwaysSlice";
import { FormInput, FormSelect, FormTextarea } from '../../components/Form/Form';
import { uploadTextResource, uploadLinkResource, uploadPdfResource } from '../../api/resources';
import { selectAllCommunities } from '../../features/communitiesSlice';
import { createLearningPathway } from '../../api/learningPathway';
import { createCommunity } from '../../api/communities';
import { selectCurrentUser } from '../../features/userSlice';
import { asyncFetchResources } from '../../features/resourcesSlice';
import Success from '../Notification/Success';
import { LoginFirstBlock } from '../PleaseLogin/PleaseLogin';
// import { FormControl, Select, MenuItem, InputLabel } from "@mui/material";


export default function Upload(props) {
    const [title, setTitle] = useState("");
    const [content, setContent] = useState("");
    const [description, setDescription] = useState("");
    const [pathway, setPathway] = useState("choose");
    const [newPathway, setNewPathway] = useState({ title: "", desc: "" });
    const [community, setCommunity] = useState("choose");
    const [newCommunity, setNewCommunity] = useState({ title: "", desc: "" });
    const [linkType, setLinkType] = useState();
    const fileInput = useRef(null);
    const user = useSelector(selectCurrentUser);

    const { postIsOpen, setPostIsOpen } = useContext(GlobalContext);

    const [error, setError] = useState("");
    const [message, setMessage] = useState("");

    const dispatch = useDispatch();
    // const allPathways = useSelector(selectAllPathways);
    const allMyPathways = useSelector(selectAllMyPathways);
    const allCommunities = useSelector(selectAllCommunities);
    // const allPathwaysStatus = useSelector(selectAllPathwaysStatus);
    const [selectedType, setSelectedType] = useState('Link');
    const [selectedPathwayValue, setSelectedPathwayValue] = useState('choose');
    const [selectedCommunityValue, setSelectedCommunityValue] = useState('choose');

    useEffect(() => {
        if (user && user.userId) {
            dispatch(asyncFetchMyPathways(user.userId));
        }
    }, [dispatch, user]);


    const handleUploadResource = async (e) => {
        e.preventDefault();

        // GUARD //
        if (!props.pathwayId) {
            if (!(!isNaN(pathway) || (pathway === "add" && ((newPathway.title && newPathway.desc) && (!isNaN(community) || (community === "add" && newCommunity.title && newCommunity.desc)))))) {
                setError("Please fill in all fields");
                return;
            }
        }

        try {
            // CREATE NEW PATHWAY OR COMMUNITY IF NEEDED //
            let pathwayId = null;
            let communityId = null;

            if (!props.pathwayId) {
                if (pathway === "add") {
                    if (community === "add") {
                        console.log(newCommunity);
                        const response = await createCommunity({
                            userId: user.userId,
                            content: {
                                name: newCommunity.title,
                                description: newCommunity.desc,
                            }
                        });
                        communityId = response.province_id;
                    } else {
                        communityId = +community;
                    }

                    const res = await createLearningPathway({
                        userId: user.userId,
                        content: {
                            title: newPathway.title,
                            description: newPathway.desc,
                            communityId: communityId,
                        }
                    });
                    pathwayId = res.pathway_id;
                } else {
                    pathwayId = +pathway;
                }
            } else {
                pathwayId = props.pathwayId;
            }

            // POST THE RESOURCE //
            let response = "";
            if (selectedType === "Text") {
                console.log(title);
                response = await uploadTextResource({
                    userId: user.userId,
                    content: {
                        title,
                        content: description,
                        pathwayId: +pathwayId
                    }
                });
            } else if (selectedType === "Link") {
                response = await uploadLinkResource({
                    userId: user.userId,
                    content: {
                        url: content,
                        title: title || "",
                        description: description || "",
                        pathwayId: +pathwayId,
                        type: "article", // TODO: change this
                    }
                });
            } else if (selectedType === "PDF") {
                if (fileInput.current.files[0].type !== "application/pdf") {
                    setError("File is not a PDF");
                }
                const formData = new FormData();
                formData.append('userId', user.userId);
                formData.append('pathway_id', +pathwayId);
                formData.append('title', title);
                formData.append('description', description);
                formData.append('file', fileInput.current.files[0]);

                response = await uploadPdfResource(formData);
            }

            // CHECK RESPONSE //
            console.log(response);
            if (response &&
                (selectedType === "Text" && response.txt_id > 0)
                ||
                (selectedType === "Link" && response.link_id > 0)
                ||
                (selectedType === "PDF" && response.pdf_id > 0)
            ) {
                if (props.pathwayId) {
                    setMessage("");
                    props.setPostSuccess(true);
                    setTimeout(() => {
                        props.setPostIsOpen(false);
                    }, 1000);
                } else {
                    setMessage("Resource posted successfully!");
                }
                setError("");
                setTitle("");
                setContent("");
                setDescription("");
                setSelectedPathwayValue("choose");
                setPathway("choose");
                setPostIsOpen(false);
                if (props.pathwayId && user && user.userId) {
                    dispatch(asyncFetchResources({ userId: user.userId, pathwayId: props.pathwayId }));
                }
                // TODO: add more
            } else {
                throw new Error("response error");
            }

        } catch (error) {
            console.error(error);
            setError("An error occured, please try again later, or contact us.");
        }
    };

    const setPathwayValue = (value) => {
        setSelectedPathwayValue(value);
        setPathway(value);
    };
    const setCommunityValue = (value) => {
        setSelectedCommunityValue(value);
        setCommunity(value);
    };

    const handleTypeClick = (type) => {
        setSelectedType(type);
    };


    return (
        <>
            <div className={`upload-container ${props.disabled && "disabled"}`}>
                {!props.pathwayId && <h1 className='h-title'>Create a resource</h1>}
                {props.disabled && <LoginFirstBlock />}
                <div className='types-container flex'>
                    <p>Resource Type</p>
                    <div className='options-container flex' onClick={(e) => handleTypeClick(e.target.textContent)}>
                        <button className={selectedType === 'Link' ? 'selected' : ''}>Link</button>
                        <button className={selectedType === 'Text' ? 'selected' : ''}>Text</button>
                        <button className={selectedType === 'PDF' ? 'selected' : ''}>PDF</button>
                    </div>
                </div>
                <form onSubmit={handleUploadResource} onChange={() => { setError(""); setMessage(""); }}>
                    <div>
                        <FormInput id="title" type="text" value={title} placeholder={selectedType === "Link" ? "Title (optional)" : "Title"} update={setTitle} required={selectedType !== "Link"} disabled={props.disabled} />
                        {selectedType === "Link" &&
                            <FormTextarea id="desc" class="url" value={content} placeholder="URL" update={setContent} required={true} disabled={props.disabled} />
                        }
                        {selectedType === "PDF" &&
                            <input type="file" className="file" style={{ marginBottom: "12px" }} ref={fileInput} required disabled={props.disabled} />
                        }
                        <FormTextarea id="desc" class="" value={description} placeholder={selectedType === "Text" ? "Text" : (selectedType === "Link" ? "Description (optional)" : "Description")} update={setDescription} required={selectedType !== "Link"} disabled={props.disabled} />
                        {/* <FormInput id="subject" label="Subject" type="text" value={title} placeholder="" update={setTitle} required={true} /> */}
                        {!props.pathwayId
                            &&
                            <>
                                <FormSelect
                                    id="pathway"
                                    label="Learning Pathway:"
                                    selectedValue={selectedPathwayValue}
                                    choose={{ value: "choose", text: "Please choose a learning pathway" }}
                                    update={setPathwayValue}
                                    options={allMyPathways && Object.keys(allMyPathways).map(id => allMyPathways[id])}
                                    required={true}
                                    new={{
                                        value: "add", text: "Add a new learning pathway"
                                    }}
                                    type="pathway"
                                    disabled={props.disabled}
                                />
                                {pathway === "add" &&
                                    <>
                                        <div className='add-new flex'>
                                            <FormInput id="new-pathway-title" type="text" value={newPathway.title} placeholder="Pathway name" required={pathway === "Add a new learning pathway"} update={(value) => setNewPathway(prev => ({ ...prev, title: value }))} disabled={props.disabled || pathway !== "add"} />
                                            <FormInput id="new-pathway-desc" type="text" value={newPathway.desc} placeholder="Description" required={pathway === "Add a new learning pathway"} update={(value) => setNewPathway(prev => ({ ...prev, desc: value }))} disabled={props.disabled || pathway !== "add"} />
                                        </div>

                                        <FormSelect
                                            id="community"
                                            label="Community:"
                                            selectedValue={selectedCommunityValue}
                                            choose={{ value: "choose", text: "Please choose a community" }}
                                            update={setCommunityValue}
                                            options={allCommunities && Object.keys(allCommunities).map(id => allCommunities[id])}
                                            required={pathway === "Add a new learning pathway"}
                                            new={{ value: "add", text: "Add a new community" }}
                                            type="province"
                                            disabled={props.disabled}
                                        />
                                    </>
                                }
                                {pathway === "add" && community === "add" && community !== "choose" &&
                                    <div className='add-new flex'>
                                        <FormInput id="new-community-title" type="text" value={newCommunity.title} placeholder="Community name" required={community === "Add a new community"} update={(value) => setNewCommunity(pre => ({ ...pre, title: value }))} disabled={props.disabled || community !== "add"} />
                                        <FormInput id="new-community-desc" type="text" value={newCommunity.desc} placeholder="Description" required={community === "Add a new community"} update={(value) => setNewCommunity(pre => ({ ...pre, desc: value }))} disabled={props.disabled || community !== "add"} />
                                    </div>
                                }
                            </>

                        }


                    </div>
                    {error && <p className='error' style={{ color: "#C9270A", marginTop: "24px" }}>{error}</p>}
                    {message && <p className='message' style={{ color: "#007D47", opacity: "0.7", marginTop: "24px" }}>{message}</p>}
                    <button type="submit" className='upload-btn-inner' disabled={props.disabled}>Upload</button>

                    {/* <FormInput className="tags" label="Tags" type="text" value={title} placeholder="" update={setTitle} required={true} /> */}
                </form>
                {props.postSuccess && <Success message={props.successMessage} />}
            </div >

        </>
    );
}