import React from 'react';
import './ContactUsPage.scss';
import { Helmet } from 'react-helmet';

export default function ContactUsPage() {
    return (
        <>
            <Helmet>
                <title>Contact Us | Tecademia</title>
                <meta
                    name='description'
                    content=''
                />
                <meta
                    name='keywords'
                    content=''
                />
            </Helmet>
            <div className={"contact-us-page-container"}>
                Contact page
            </div>
        </>
    );
}