import React, { useContext, useEffect, useState, useMemo } from 'react';
import './SearchResultPage.scss';
import { Helmet } from 'react-helmet';
import { Link, useLocation, useParams } from 'react-router-dom';
import { performSearch } from '../../api/search';
import { GlobalContext } from '../../context/globalContext';
import LinkBlock from '../../components/Blocks/LinkBlock';
import QuizBlock from '../../components/Blocks/QuizBlock';
import { useSelector } from 'react-redux';
import { selectCurrentUser } from '../../features/userSlice';
import TextBlock from '../../components/Blocks/TextBlock';
import PdfBlock from '../../components/Blocks/PdfBlock';
import CommunityBlock from '../../components/Blocks/CommunityBlock';
import PathwayBlock from '../../components/Blocks/PathwayBlock';
import { selectAllMyPathways } from '../../features/pathwaysSlice';

export default function SearchResultPage() {
    const location = useLocation();
    const { searchResult, setSearchResult } = useContext(GlobalContext);
    const queryParams = useMemo(() => new URLSearchParams(location.search), [location.search]);
    const currentQuery = queryParams.get('query');
    const includesCommunities = queryParams.get('communities') === 'true';
    const includesLearningPathways = queryParams.get('pathways') === 'true';
    const includesLinkBlocks = queryParams.get('links') === 'true';
    const includesTxtBlocks = queryParams.get('texts') === 'true';
    const includesPdfUploads = queryParams.get('pdfs') === 'true';
    const user = useSelector(selectCurrentUser);
    const pathwayMyList = useSelector(selectAllMyPathways);


    useEffect(() => {
        if (currentQuery && user && user.userId && currentQuery && currentQuery !== "undefined") {
            (async () => {
                try {
                    const result = await performSearch({
                        userId: user.userId,
                        content: {
                            "query": currentQuery,
                            "includeCommunities": includesCommunities,
                            "includePathways": includesLearningPathways,
                            "includeCities": false,
                            "includeLinkBlocks": includesLinkBlocks,
                            "includeTxtBlocks": includesTxtBlocks,
                            "includePdfUploads": includesPdfUploads,
                            "communityId": -1,
                            "k": 20
                        }
                    });
                    setSearchResult(result);
                } catch (error) {
                    console.error("Failed to search: " + currentQuery, error);
                }
            })();
        }
    }, [currentQuery, user, location.search]);

    const uniqueResults = useMemo(() => {
        let groupedResults = {
            pathways: [],
            txtBlocks: [],
            pdfUploads: [],
            linkBlocks: [],
            communities: []
        };

        if (searchResult && searchResult.length > 0) {
            let ids = {
                pathways: new Set(),
                txtBlocks: new Set(),
                pdfUploads: new Set(),
                linkBlocks: new Set(),
                communities: new Set()
            };

            console.log(searchResult);
            searchResult.forEach(item => {
                switch (item.database_of_origin) {
                    case 'pathways':
                        if (!ids.pathways.has(item.document.pathway_id)) {
                            groupedResults.pathways.push(item);
                            ids.pathways.add(item.document.pathway_id);
                        }
                        break;
                    case 'txt_blocks':
                        if (!ids.txtBlocks.has(item.document.txt_id)) {
                            groupedResults.txtBlocks.push(item);
                            ids.txtBlocks.add(item.document.txt_id);
                        }
                        break;
                    case 'pdf_uploads':
                        if (!ids.pdfUploads.has(item.document.pdf_id)) {
                            groupedResults.pdfUploads.push(item);
                            ids.pdfUploads.add(item.document.pdf_id);
                        }
                        break;
                    case 'link_blocks':
                        if (!ids.linkBlocks.has(item.document.link_id)) {
                            groupedResults.linkBlocks.push(item);
                            ids.linkBlocks.add(item.document.link_id);
                        }
                        break;
                    case 'provinces':
                        if (!ids.communities.has(item.document.province_id)) {
                            groupedResults.communities.push(item);
                            ids.communities.add(item.document.province_id);
                        }
                        break;
                    default:
                        break;
                }
            });
        }
        return groupedResults;
    }, [searchResult]);

    return (
        <>
            <Helmet>
                <title>{currentQuery || "Search Result"} | Tecademia</title>
                <meta name='description' content='' />
                <meta name='keywords' content='' />
            </Helmet>
            <div className={"search-result-page-container"}>
                <h1 className='h-title cl-dark-gray'>Result for <span className='query'>{currentQuery}</span></h1>

                {Object.keys(uniqueResults).map(key => {
                    if (uniqueResults[key].length > 0) {
                        return uniqueResults[key].map((i) => {
                            // console.log(i);
                            if (key === "pathways") {
                                console.log(i.document);
                                return (
                                    <PathwayBlock
                                        resource={{
                                            name: i.document.name,
                                            description: i.document.description,
                                            id: i.document.pathway_id,
                                            communityId: i.document.province_id
                                        }}
                                        canDelete={false}
                                    // canDelete={pathwayMyList && pathwayMyList[i.document.pathway_id]} key={i.document.pathway_id}
                                    />
                                );
                            }
                            else if (key === "txtBlocks") {
                                // console.log("in txtBlocks");
                                return (<TextBlock resource={{ title: i.document.title, content: i.document.content, id: i.document.txt_id }} key={i.document.txt_id} canDelete={false} />);
                            }
                            else if (key === "pdfUploads") {
                                // console.log("in pdfUploads");
                                return (
                                    <PdfBlock
                                        resource={{
                                            file_path: i.document.file_path,
                                            title: i.document.title,
                                            description: i.document.description,
                                            id: i.document.pdf_id,
                                        }}
                                        key={i.document.pdf_id}
                                        canDelete={false} />
                                );
                            }
                            else if (key === "linkBlocks") {
                                // console.log("in linkBlocks");
                                return (
                                    <LinkBlock
                                        resource={{
                                            description: i.document.description,
                                            icon_url: i.document.icon_url,
                                            thumbnail_url: i.document.thumbnail_url,
                                            title: i.document.title,
                                            url: i.document.url,
                                            id: i.document.link_id,
                                        }}
                                        key={i.document.link_id}
                                        canDelete={false} />
                                );
                            }
                            else if (key === "communities") {
                                // console.log("in communities");
                                return (
                                    <CommunityBlock
                                        resource={{
                                            name: i.document.name,
                                            description: i.document.description,
                                            id: i.document.province_id,
                                        }}
                                    />
                                );
                            }

                        });
                    }

                })}
            </div>
        </>
    );
}