import { combineReducers, configureStore } from "@reduxjs/toolkit";
import resourcesReducer from "../features/resourcesSlice";
import pathwaysReducer from "../features/pathwaysSlice";
import communitiesReducer from "../features/communitiesSlice";
import userReducer from "../features/userSlice";
import thunk from 'redux-thunk';

import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web

const persistConfig = {
    key: "root",
    storage,
};

const rootReducer = combineReducers({
    resources: resourcesReducer,
    pathways: pathwaysReducer,
    communities: communitiesReducer,
    user: userReducer
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
    reducer: persistedReducer,
    middleware: [thunk]
});

export const persistor = persistStore(store);
