import React, { useContext } from 'react';
import './CareersChallengePage.scss';
import { Helmet } from 'react-helmet';
import { NavLink } from 'react-router-dom';
import { GlobalContext } from '../../context/globalContext';

export default function CareersChallengePage() {
    const { isMobile } = useContext(GlobalContext);
    return (
        <>
            <Helmet>
                <title>Careers Challenges | Tecademia</title>
                <meta name='description' content='' />
                <meta name='keywords' content='' />
            </Helmet>
            <div className={"careers-challenge-page-container"}>
                <div className='blur'></div>
                <div className='blur2 blur'></div>
                <div className='header flex'>
                    <p className='logo'>Tecademia</p>
                    <ul className='flex list'>
                        <li><NavLink to="/" target='_blank'>Learning Pathways</NavLink></li>
                        {!isMobile &&
                            <>
                                <li><a href="#challenges-section">Challenges</a></li>
                                <li><a href="#timeline-section">Timeline</a></li>
                            </>
                        }
                        <li className='application'><a target="_blank" href='https://docs.google.com/forms/d/e/1FAIpQLSdDbRAnNInhlhQs2e5IQ2OQRrJrK3On06PSxu0HD16hi22ygw/viewform?usp=sharing'>Register</a></li>
                    </ul>
                </div>
                <div className='intro-container'>
                    <h1>Win internships and prize money<br />with the <span>Tecademia</span> Careers Challenge 2024!</h1>
                    <p>Write an essay, build an app, or pitch your idea to investors. Go from beginner to expert in just 4 weeks.</p>
                    <a href="https://docs.google.com/forms/d/e/1FAIpQLSdDbRAnNInhlhQs2e5IQ2OQRrJrK3On06PSxu0HD16hi22ygw/viewform?usp=sharing" target='_blank'>Register Your Interest!</a>
                </div>
                <div className='challenge-content flex'>
                    <div className='challenges-container section' id="challenges-section">
                        <h2>Challenge Pathways</h2>
                        <ul className='flex'>
                            <li>
                                <p>
                                    <span class="material-symbols-outlined">
                                        terminal
                                    </span>
                                </p>
                                <h3>Coding Crusade</h3>
                                <p>Build your own app!</p>
                            </li>
                            <li>
                                <p>
                                    <span class="material-symbols-outlined">
                                        emoji_objects
                                    </span>
                                </p>
                                <h3>Entrepreneurial Elevator</h3>
                                <p>Pitch your business idea to investors!</p>
                            </li>
                            <li>
                                <p>
                                    <span class="material-symbols-outlined">
                                        history_edu
                                    </span>
                                </p>
                                <h3>Essay Expedition</h3>
                                <p>Do your own research and write your own paper!</p>
                            </li>
                        </ul>
                    </div>
                    <div className='timeline-container section' id="timeline-section">
                        <h2>Timeline</h2>
                        <ul>
                            <div>
                                <li>
                                    <p>Start (20/11/2023): Watch introductory video on YouTube (30 mins)</p>
                                </li>
                            </div>
                            <div>

                                <h3>Pretrain Phase<br /><span>guided e-learning and independent research on Tecademia</span></h3>
                                <li className='submit'>
                                    <p>Week 1 (24/11/2023): 1st hand-in</p>
                                    <a>Submit</a>
                                </li>
                                <li className='submit'>
                                    <p>Week 2 (01/12/2023): 2nd hand-in</p>
                                    <a>Submit</a>
                                </li>
                                <li className='submit'>
                                    <p>Week 3 (08/12/2023): 3rd hand-in</p>
                                    <a>Submit</a>
                                </li>
                            </div>
                            <div>

                                <h3>Submission Phase</h3>
                                <li className='submit'>
                                    <p>Week 4 (15/12/2023): submission deadline</p>
                                    <a>Submit</a>
                                </li>
                            </div>
                            <div>
                                <h3>Prize Phase</h3>
                                <li>
                                    <p>Week 11 (02/02/2023)</p>
                                    <p>announcing winners</p>
                                </li>
                            </div>
                        </ul>
                    </div>
                    {/* <div className='submission-container section'>
                        <h2>Submission</h2>
                        <p>Coming Soon!</p>
                    </div> */}
                </div>
            </div>
        </>
    );
}

export function CareersChallengeSubmissionPage() {
    return (
        <>
            <Helmet>
                <title>Submission | Careers Challenges | Tecademia</title>
                <meta name='description' content='' />
                <meta name='keywords' content='' />
            </Helmet>
            <div className={"careers-challenge-submission-page-container"}>
            </div>
        </>
    );
}