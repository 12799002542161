import React from 'react';
import './PleaseLogin.scss';
import { useSelector } from 'react-redux';
import { selectCurrentUser } from '../../features/userSlice';
import { userLoggedIn } from '../../util/userLoggedIn';

export default function PleaseLogin({ children }) {
    const user = useSelector(selectCurrentUser);

    if (userLoggedIn(user)) {
        return children;
    } else {
        return (
            <div className={"please-login-container"}>
                Please login first
            </div>
        );
    }

}

export function LoginFirstBlock() {
    return <p className='login-first'>Please login first</p>;
}