import React from 'react';
import './AboutUsPage.scss';
import { Helmet } from 'react-helmet';

export default function AboutUsPage() {
    return (
        <>
            <Helmet>
                <title>About Us | Tecademia</title>
                <meta
                    name="description"
                    content=""
                />
                <meta
                    name="keywords"
                    content=""
                />
            </Helmet>
            <div className={"about-us-page-container"}>
                About page
            </div>
        </>
    );
}