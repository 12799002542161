import { axiosCustom, axiosCustomNoApi } from "./axiosCustom";

/**
 * 
 * @param {string} firebaseUid
 * @param {string} token
 * @param {string} username
 * @param {string} email
 * header: authorization: string
 * @returns 
 */

export const createUser = async ({ firebaseUid, username, email }) => {
    try {
        const response = await axiosCustom.post(`/users?firebase_uid=${firebaseUid}`, { username, email });
        return response.data;
    } catch (error) {
        if (error.response && error.response.data && error.response.data.detail === "Email already exists" || error.response.data.detail === "Username already exists") {
            // 因为是inactive的，所以get _user_id拿不到user_id, 所以只能用firebaseUid
            console.log("in reactivate user");
            const response = await reactivateUser({ firebaseUid });
            if (response && response.data && response.data.user) {
                return response.data.user;
            }
        } else {
            console.error("Error creating a user:", error);
            throw error;
        }
    }
};

export const reactivateUser = async () => {
    try {
        const response = await axiosCustom.put(`/users/restore`);
        return response.data;
    } catch (error) {
        console.error("Error reactivating a user:", error);
        throw error;
    }
};

export const deactivateUser = async ({ userId }) => {
    console.log("Deleting user" + userId);
    try {
        const response = await axiosCustom.delete(`/users?user_id=${userId}`);
        console.log(response);
        return response.data;
    } catch (error) {
        console.error("Error deleting a user:", error);
        throw error;
    }
};

export const fetchUserId = async (firebaseUid) => {

    try {
        const response = await axiosCustomNoApi.get(`/get_active_user_id?firebase_uid=${firebaseUid}`);
        return [true, response.data];
    } catch (error) {
        if (error.response && error.response.data && (error.response.data.detail === "Not Found" || error.response.data.detail === "User not found")) {
            return [false, null];
        } else {
            console.error("Error fetching a user id by firebase uid:", error);
            throw error;
        }
    }
};

export const fetchUser = async ({ userId }) => {
    try {
        const response = await axiosCustom.get(`/users/id?user_id=${userId}`);
        return response.data;
    } catch (error) {
        console.error("Error fetching a user:", error);
        throw error;
    }
};

export const setUserAccess = async ({ pathwayId, userId, accessType }) => {
    try {
        const response = await axiosCustom.post(`/pathway/${pathwayId}/access`, { user_id: userId, access_type: accessType });
        return response.data;
    } catch (error) {
        console.error("Error deleting a user:", error);
        throw error;
    }
};
