import React, { createContext, useState, useEffect, useRef } from 'react';
import App from '../app/App';
import { getAllCommunities } from '../api/communities';
import { auth } from '../config/firebaseConfig';
import { createUser } from '../api/user';
import { useDispatch, useSelector } from 'react-redux';
import { asyncFetchAllCommunities, selectAllCommunities, selectAllCommunitiesError, selectAllCommunitiesStatus } from '../features/communitiesSlice';

export const GlobalContext = createContext();

export const GlobalContainer = (props) => {

    const [isMobile, setIsMobile] = useState(true);
    const [isSmallScreen, setIsSmallScreen] = useState(true);
    const [isNavOpen, setIsNavOpen] = useState(false);

    const [analytics, setAnalytics] = useState(localStorage.getItem("cookieAnalytics") ? +localStorage.getItem("cookieAnalytics") : 0);
    const [preference, setPreference] = useState(localStorage.getItem("cookiePreference") ? +localStorage.getItem("cookiePreference") : 0);
    const [privacy, setPrivacy] = useState(localStorage.getItem("cookiePrivacy") ? +localStorage.getItem("cookiePrivacy") : 0);

    const dispatch = useDispatch();
    const allCommunities = useSelector(selectAllCommunities);
    const allCommunitiesStatus = useSelector(selectAllCommunitiesStatus);

    const menuButtonRef = useRef(null);

    // const [allCommunities, setAllCommunities] = useState();
    const [currentUser, setCurrentUser] = useState(null);
    const [user, setUser] = useState();
    const [filter, setFilter] = useState(0);
    const [searchResult, setSearchResult] = useState([]);
    const [postIsOpen, setPostIsOpen] = useState(false);
    const [searchQuery, setSearchQuery] = useState("");
    // const [lastSignInTime, setLastSignInTime] = useState();

    const [searchFilterBool, setSearchFilterBool] = useState({
        includesCommunities: false,
        includesLearningPathways: true,
        includesLinkBlocks: false,
        includesTxtBlocks: false,
        includesPdfUploads: false,
    });

    const [searchFilterOther, setSearchFilterOther] = useState({
        communityId: 0,
    });


    // let lastSignInTime = null;

    // useEffect(() => {
    //     auth.onAuthStateChanged(user => {
    //         if (user) {
    //             // If lastSignInTime is null or different, it's a genuine login
    //             if (!lastSignInTime || lastSignInTime !== user.metadata.lastSignInTime) {
    //                 setUser(user);
    //                 lastSignInTime = user.metadata.lastSignInTime;
    //             }
    //         } else {
    //             lastSignInTime = null;
    //             setCurrentUser(null);
    //         }
    //     });
    // });

    // let b = true;
    // useEffect(() => {
    //     if (user && b) {
    //         async function func() {
    //             // console.log(currentUser);
    //             if (currentUser) {
    //                 let userTemp = await createUser({
    //                     username: user.displayName,
    //                     email: user.email,
    //                     firebase_uid: user.uid
    //                 });
    //                 b = false;
    //                 setCurrentUser(userTemp);
    //                 // console.log(userTemp);
    //             }
    //         }
    //         func();
    //     }
    // }, [user]);

    function setAnalyticsFunc(n) {
        setAnalytics(n);
        props.setAnalytics(n);
    }



    useEffect(() => {
        dispatch(asyncFetchAllCommunities());
    }, [dispatch]);



    // RESPONSIVE DESIGN SCREEN SIZE //
    useEffect(() => {
        setIsMobile(window.innerWidth < 430);
        setIsSmallScreen(window.innerWidth < 1000);

        function handleResize() {
            setIsMobile(window.innerWidth < 430);
            setIsSmallScreen(window.innerWidth < 1000);
            setIsNavOpen(false);
        }

        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);


    return (
        <GlobalContext.Provider value={{
            isMobile, setIsMobile,
            isSmallScreen, setIsSmallScreen,
            allCommunities,
            currentUser, setCurrentUser,
            filter, setFilter,
            searchResult, setSearchResult,
            // searchQuery, setSearchQuery,
            // searchFilterBool, setSearchFilterBool,
            // searchFilterOther, setSearchFilterOther,
            postIsOpen, setPostIsOpen,
            isNavOpen, setIsNavOpen,
            menuButtonRef,
            preference, setPreference,
            privacy, setPrivacy,
            analytics, setAnalyticsFunc,
        }} >
            <App />
        </GlobalContext.Provider>
    );
};