import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getResourcesByLearningPathwayId } from "../api/resources";


const initialState = {
    resourcesData: [],
    status: "idle",
    error: null,
};


export const asyncFetchResources = createAsyncThunk("resources/fetchResources", async ({ userId, pathwayId }) => {
    const resources = await getResourcesByLearningPathwayId({ userId, pathwayId });
    return resources;
});



export const resourcesSlice = createSlice({
    name: "resources",
    initialState,
    reducers: {
        setResources: (state, action) => {
            state.resourcesData = action.payload;
        },
        // TODO: change to edit fav in resource
        /*
        editFavInDevelopments: (state, action) => {
            const { id, bool } = action.payload;
            const dev = state.developments.find((d) => d['unique-id'] === id);
            if (dev) {
                dev.favourite = bool;
            }
        },
        */
        setResourcesStatus: (state, action) => {
            state.status = action.payload;
        },
        clearResourcesData: (state) => {
            state.resourcesData = [];
            state.status = "idle";
            state.error = null;
        }
    },
    extraReducers(builder) {
        builder
            .addCase(asyncFetchResources.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(asyncFetchResources.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.resourcesData = action.payload;
            })
            .addCase(asyncFetchResources.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.error.message;
            });
    }
});




export const selectAllResources = (state) => state.resources.resourcesData;
export const selectAllResourcesStatus = (state) => state.resources.status;


export const { setResources, setResourcesStatus, clearResourcesData } = resourcesSlice.actions;
export default resourcesSlice.reducer;