import React, { useContext, useEffect, useState } from 'react';
import './Header.scss';
import { NavLink, useActionData, useLocation, useNavigate } from 'react-router-dom';
import { avatarPlaceholder } from '../../assets/assetsExports';
import { signInWithGoogle, signOut } from '../../features/user';
import { GlobalContext } from '../../context/globalContext';
import { performSearch } from '../../api/search';
import { useDispatch, useSelector } from 'react-redux';
import { asyncDeleteUser, asyncSignInWithGoogle, asyncSignOut, selectCurrentUser } from '../../features/userSlice';
import { FormCheckbox } from '../../components/Form/Form';
import { userLoggedIn } from '../../util/userLoggedIn';
import Banner from '../Banner/Banner';

export default function Header() {
    const navigate = useNavigate();
    const location = useLocation();
    const { isNavOpen, setIsNavOpen, isSmallScreen, menuButtonRef } = useContext(GlobalContext);
    const [showFilter, setShowFilter] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');
    const [formOpen, setFormOpen] = useState(false);
    const [localFilterBool, setLocalFilterBool] = useState({
        includesCommunities: false,
        includesLearningPathways: true,
        includesLinkBlocks: false,
        includesTxtBlocks: false,
        includesPdfUploads: false,
    });

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        setSearchQuery(queryParams.get('query') || '');
        setLocalFilterBool({
            includesCommunities: queryParams.get('communities') === 'true',
            includesLearningPathways: queryParams.get('pathways') ? queryParams.get('pathways') === 'true' : true,
            includesLinkBlocks: queryParams.get('links') === 'true',
            includesTxtBlocks: queryParams.get('texts') === 'true',
            includesPdfUploads: queryParams.get('pdfs') === 'true',
        });
    }, []);

    const toggleNav = () => {
        const newIsNavOpen = !isNavOpen;
        setIsNavOpen(newIsNavOpen);
        document.querySelector("html").style.overflow = newIsNavOpen ? 'hidden' : "visible";
    };

    const dispatch = useDispatch();
    const user = useSelector(selectCurrentUser);

    const handleSearch = async (e) => {
        e.preventDefault();
        if (isSmallScreen) setFormOpen(false);
        setShowFilter(false);
        if (searchQuery) {
            navigate(`/search?query=${searchQuery.trim()}&communities=${localFilterBool.includesCommunities}&pathways=${localFilterBool.includesLearningPathways}&links=${localFilterBool.includesLinkBlocks}&texts=${localFilterBool.includesTxtBlocks}&pdfs=${localFilterBool.includesPdfUploads}`);
        }
    };

    const handleSignIn = () => {
        if (userLoggedIn(user)) {
            return;
        } else {
            dispatch(asyncSignInWithGoogle());
            if (document.querySelector(".user-actions-container")) document.querySelector(".user-actions-container").style.display = "none";
        }
    };
    const handleSignOut = () => {
        if (userLoggedIn(user)) {
            dispatch(asyncSignOut());
        }
    };

    const handleCheckboxChange = (value, filterName) => {
        setLocalFilterBool({
            ...localFilterBool,
            [filterName]: value
        });
    };


    const handleDeleteUser = () => {
        dispatch(asyncDeleteUser(user));
    };

    const urlIsValid = (url) => {
        fetch(url)
            .then(response => {
                if (!response.ok) {
                    return false;
                }
                return true;
            })
            .catch(error => {
                return false;
            });
    };


    return (
        <div className={"header-container"}>
            <Banner />
            <div className='flex header-content'>
                <div className='logo'>
                    {isSmallScreen && <span ref={menuButtonRef} onClick={toggleNav} className="material-symbols-outlined">menu</span>}
                    <NavLink to="/">T{!isSmallScreen && "ecademia"}</NavLink>
                </div>
                {/* {isSmallScreen && <span className="material-symbols-outlined search-icon" onClick={() => { setFormOpen(!formOpen); }}>search</span>} */}
                {(!isSmallScreen || (isSmallScreen && formOpen)) &&
                    <form
                        className='search-container flex'
                        onSubmit={handleSearch}
                        onMouseEnter={() => { !isSmallScreen && setShowFilter(true); }}
                        onMouseLeave={() => { !isSmallScreen && setShowFilter(false); }}
                        onKeyDown={(e) => { if (e.key === 'Enter') { e.preventDefault(); handleSearch(e); } }}
                    >
                        <div className='input-container'>
                            <div className='search'>
                                <span className="material-symbols-outlined">search</span>
                                <input type="text" value={searchQuery} onChange={(e) => { setSearchQuery(e.target.value); }} placeholder='Search for a Learning Path or a Community ...' />
                                <button type="submit">Search</button>
                            </div>
                            {((!isSmallScreen && showFilter) || (isSmallScreen && formOpen)) &&
                                <div className='filter-container-outer'>
                                    <div className='filter-container'>
                                        <div className='top flex'>
                                            <p>Search results will include:</p>
                                            <div className='button-container'>
                                                <button onClick={(e) => { e.preventDefault(); setLocalFilterBool({ includesCommunities: true, includesLearningPathways: true, includesLinkBlocks: true, includesTxtBlocks: true, includesPdfUploads: true }); }}>Select All</button>
                                                <button onClick={(e) => { e.preventDefault(); setLocalFilterBool({ includesCommunities: false, includesLearningPathways: false, includesLinkBlocks: false, includesTxtBlocks: false, includesPdfUploads: false }); }}>Deselect All</button>
                                            </div>
                                        </div>
                                        <div className='filter-form'>
                                            <FormCheckbox className="includesCommunities" label="Communities" value={localFilterBool.includesCommunities} update={(value) => handleCheckboxChange(value, 'includesCommunities')} />
                                            <FormCheckbox className="includesLearningPathways" label="Pathways" value={localFilterBool.includesLearningPathways} update={(value) => handleCheckboxChange(value, 'includesLearningPathways')} />
                                            <FormCheckbox className="includesLinkBlocks" label="Links" value={localFilterBool.includesLinkBlocks} update={(value) => handleCheckboxChange(value, 'includesLinkBlocks')} />
                                            <FormCheckbox className="includesTxtBlocks" label="Texts" value={localFilterBool.includesTxtBlocks} update={(value) => handleCheckboxChange(value, 'includesTxtBlocks')} />
                                            <FormCheckbox className="includesPdfUploads" label="PDFs" value={localFilterBool.includesPdfUploads} update={(value) => handleCheckboxChange(value, 'includesPdfUploads')} />
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                    </form>
                }
                <div className='upload-user-container flex'>
                    {isSmallScreen && <span className="material-symbols-outlined search-icon" onClick={() => { setFormOpen(!formOpen); }}>search</span>}

                    <NavLink className="upload-btn" to="/post">Post</NavLink>
                    <div className="user-container" onClick={handleSignIn} >
                        <div className='avatar-container'>
                            <img className="avatar" src={user && userLoggedIn(user) && urlIsValid(user.photoURL) ? user.photoURL : avatarPlaceholder} alt="avatar" />
                            <p>{user && user.displayName && user.displayName}</p>
                        </div>
                        {userLoggedIn(user) &&
                            <div className='user-actions-container'>
                                <p className='logout' onClick={handleSignOut}><span>Logout</span><span className="material-symbols-outlined icon">logout</span></p>
                                <p className="delete" onClick={handleDeleteUser}>Delete</p>
                            </div>
                        }
                    </div>
                </div>
            </div>

        </div >

    );
}