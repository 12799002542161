import React, { useContext, useEffect, useState } from 'react';
import './LearningPathwayPage.scss';
import { Helmet } from 'react-helmet';
import TextBlock from '../../components/Blocks/TextBlock';
import PdfBlock from '../../components/Blocks/PdfBlock';
import LinkBlock from '../../components/Blocks/LinkBlock';
import { ActionBlock, DeleteBlock } from '../../components/Blocks/Blocks';
import { NavLink, useLocation, useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { asyncFetchResources, clearResourcesData, selectAllResources, selectAllResourcesStatus } from '../../features/resourcesSlice';
import { selectCurrentUser } from '../../features/userSlice';
import { asyncFetchMyPathways, asyncFetchPathways, clearMyPathway, clearPathways, selectAllMyPathways, selectAllMyPathwaysStatus, selectAllPathways, selectAllPathwaysStatus } from '../../features/pathwaysSlice';
import { selectAllCommunities } from '../../features/communitiesSlice';
import { performSearch } from '../../api/search';
import { FormInput, FormTextarea } from '../../components/Form/Form';
import Upload from '../../components/Upload/Upload';
import { GlobalContext } from '../../context/globalContext';
import { CommunitySkeletonLoaderPage } from '../../components/Loaders/SkeletonLoaders/SkeletonLoaders';
import { isGuest } from '../../util/userLoggedIn';
import { LoginFirstBlock } from '../../components/PleaseLogin/PleaseLogin';

export default function LearningPathwayPage() {
    const location = useLocation();
    // const dispatch = useDispatch();
    const queryParams = new URLSearchParams(location.search);
    const pathwayId = queryParams.get('pathway');
    const communityId = queryParams.get('community');
    const pathwayList = useSelector(selectAllPathways);
    // const pathwayListStatus = useSelector(selectAllPathwaysStatus);



    const pathwayName = pathwayList?.[pathwayId]?.name;

    return (
        <>
            <Helmet>
                <title>{`${pathwayName} | Tecademia`}</title>
                <meta name='description' content={pathwayName} />
                <meta name='keywords' content={pathwayName} />
            </Helmet>
            <LearningPathway pathwayId={pathwayId} limit={null} breadcrumb={true} communityId={communityId} />
        </>
    );

}
export function LearningPathway({ pathwayId, limit, breadcrumb, communityId }) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [postIsOpen, setPostIsOpen] = useState(false);
    // const { postIsOpen, setPostIsOpen } = useContext(GlobalContext);
    const location = useLocation();
    const pathwayResources = useSelector(selectAllResources);
    const pathwayResourcesStatus = useSelector(selectAllResourcesStatus);
    const pathwayList = useSelector(selectAllPathways);
    const pathwayListStatus = useSelector(selectAllPathwaysStatus);
    const pathwayMyList = useSelector(selectAllMyPathways);
    const pathwayMyListStatus = useSelector(selectAllMyPathwaysStatus);
    const communityList = useSelector(selectAllCommunities);
    const user = useSelector(selectCurrentUser);

    const [success, setSuccess] = useState(false);


    const [valueName, setValueName] = useState("");
    const [valueDesc, setValueDesc] = useState("");

    const [pathway, setPathway] = useState(null);
    const [pathwayName, setPathwayName] = useState(null);
    const [pathwayDescription, setPathwayDescription] = useState(null);
    const [communityName, setCommunityName] = useState(null);
    const [restoredValues, setRestoredValues] = useState(null);

    const [isEditing, setIsEditing] = useState(false);
    const [filter, setFilter] = useState({
        link: true,
        text: true,
        pdf: true
    });
    const [isLoading, setIsLoading] = useState(true);

    function handleSetFilter(type) {
        setFilter({
            ...filter,
            [type]: !filter[type]
        });
    }

    // useEffect(() => {
    //     setIsLoading(true);
    //     const fetchPathways = pathwayListStatus === "idle" ? dispatch(asyncFetchPathways([communityId])) : Promise.resolve();
    //     const fetchResources = user && user.userId ? dispatch(asyncFetchResources({ userId: user.userId, pathwayId: pathwayId })) : Promise.resolve();
    //     const fetchMyPathways = user && user.userId ? dispatch(asyncFetchMyPathways(user.userId)) : Promise.resolve();

    //     Promise.all([fetchPathways, fetchResources, fetchMyPathways]).then(() => {
    //         if (pathwayList && Object.keys(pathwayList).length > 0 && pathwayId) {
    //             const pathway = pathwayList[pathwayId];
    //             setPathway(pathway);
    //             setPathwayName(pathway.name);
    //             setPathwayDescription(pathway.description);
    //         }

    //         if (communityList && Object.keys(communityList).length > 0 && pathway) {
    //             setCommunityName(communityList[pathway.province_id].name);
    //         }
    //         setIsLoading(false);
    //     });
    // }, [dispatch, user, pathwayId, pathwayList, communityList, pathwayListStatus]);


    // useEffect(() => {
    //     if (pathwayListStatus === "idle") {
    //         dispatch(asyncFetchPathways([communityId]));
    //     }
    //     if (pathwayMyListStatus === "idle" && user && user.userId) {
    //         dispatch(asyncFetchMyPathways(user.userId));
    //     }
    //     if (pathwayResourcesStatus === "idle" && user && user.userId && pathwayId) {
    //         dispatch(asyncFetchResources({ userId: user.userId, pathwayId: pathwayId }));
    //     }
    // }, [dispatch, user, pathwayId]);

    useEffect(() => {
        setRestoredValues({ name: valueName, desc: valueDesc });
    }, [isEditing]);

    useEffect(() => {
        setIsLoading(true);

        const fetchPathways = dispatch(asyncFetchPathways([+communityId]));
        const fetchMyPathways = user && user.userId ? dispatch(asyncFetchMyPathways(user.userId)) : Promise.resolve();
        const fetchResources = user && user.userId && pathwayId ? dispatch(asyncFetchResources({ userId: user.userId, pathwayId: pathwayId })) : Promise.resolve();

        Promise.all([fetchPathways, fetchMyPathways, fetchResources]).then(() => setIsLoading(false));
    }, [dispatch, user, pathwayId, communityId]);


    useEffect(() => {
        if (pathwayList && Object.keys(pathwayList).length > 0 && pathwayId) {
            const pathway = pathwayList[pathwayId];
            console.log(pathway);
            if (pathway) {
                setPathway(pathway);
                setValueName(pathway.name);
                setPathwayDescription(pathway.description);
                setValueDesc(pathway.description);
                setValueName(pathway.name);
            }
        }
    }, [pathwayList, pathwayId]);

    useEffect(() => {
        if (communityList && Object.keys(communityList).length > 0 && pathway) {
            setCommunityName(communityList[pathway.province_id].name);
        }
    }, [communityList, pathway]);


    useEffect(() => {
        dispatch(clearResourcesData());
        dispatch(clearPathways());
        dispatch(clearMyPathway());
    }, [dispatch, location]);

    useEffect(() => {
        return () => {
            dispatch(clearResourcesData());
            dispatch(clearPathways());
            dispatch(clearMyPathway());
        };
    }, [dispatch]);

    // useEffect(() => {
    //     if (user && user.userId) {
    //         dispatch(asyncFetchResources({ userId: user.userId, pathwayId: pathwayId }));
    //         dispatch(asyncFetchMyPathways(user.userId));
    //     }
    //     return () => {
    //         dispatch(clearResourcesData());
    //     };
    // }, [dispatch, user, pathwayId]);

    // useEffect(() => {
    //     if (pathwayList && Object.keys(pathwayList).length > 0 && pathwayId) {
    //         setPathway(pathwayList[pathwayId]);
    //     }
    // }, [pathwayList, pathwayId]);

    // useEffect(() => {
    //     if (communityList && Object.keys(communityList).length > 0 && pathway) {
    //         setCommunityName(communityList[pathway.province_id].name);
    //     }
    // }, [communityList, pathway]);

    // useEffect(() => {
    //     if (pathway) {
    //         setPathwayName(pathway.name);
    //         setValueName(pathway.name);
    //         setPathwayDescription(pathway.description);
    //         setValueDesc(pathway.description);
    //     }
    // }, [pathway]);

    if (isLoading || pathwayResourcesStatus === "idle" || pathwayResourcesStatus === "loading") {
        return (<CommunitySkeletonLoaderPage />);
    }


    return (
        <>
            <div className='breadcrumb-container'>
                {/* {breadcrumb && pathway && <p className='breadcrumb'><NavLink to={`/community?id=${pathway.province_id}&name=${communityName.replace(/ /g, '%20')}`}>{communityName}</NavLink>&nbsp;&lt;&nbsp;<span className='current'>{pathwayName}</span></p>} */}
                {breadcrumb && pathwayId && pathway && communityName && <p className='breadcrumb'><NavLink to={`/community?id=${pathway.province_id}&name=${communityName.replace(/ /g, '%20')}`}>{communityName}</NavLink>&nbsp;&lt;&nbsp;<span className='current'>{valueName}</span></p>}
                {/* {breadcrumb && pathwayId && pathway && <p className='breadcrumb'><NavLink to={`/community?id=${pathway.province_id}&name=${communityName.replace(/ /g, '%20')}`}>{communityName}</NavLink>&nbsp;&lt;&nbsp;<span className='current'>{pathwayName}</span></p>} */}
                {pathwayId && pathwayMyList && pathwayMyList[pathwayId] && pathway && communityName &&
                    <ActionBlock
                        type="pathway"
                        id={pathwayId}
                        setIsEditing={setIsEditing}
                        setIsDeleted={null}
                        // setIsDeleted={() => navigate('/')}
                        community={{ id: pathway.province_id, name: communityName }}
                        restoreValue={() => { setValueDesc(restoredValues.desc); setValueName(restoredValues.name); }}
                        content={{
                            name: valueName.trim(),
                            description: valueDesc.trim(),
                            province_id: pathway.province_id,
                            city_id: pathway.city_id,
                        }}
                    />}
                {/* {pathwayId && pathwayMyList && pathwayMyList[pathwayId] && pathway && <DeleteBlock type="pathway" id={pathwayId} setIsDeleted={null} community={{ id: pathway.province_id, name: communityName }} />} */}
            </div>
            <div className={`pathway-container ${breadcrumb && "breadcrumb"}`} key={pathwayId} >
                <div className='top'>
                    <div className='mid'>
                        <div>
                            {isEditing
                                ?
                                <>
                                    <FormInput value={valueName} update={setValueName} required={true} />
                                    <FormTextarea value={valueDesc} update={setValueDesc} class="description-input" />
                                </>
                                :
                                <>
                                    <h4>{valueName}</h4>
                                    <p className='desc'>{valueDesc}</p>
                                </>
                            }
                        </div>
                    </div>
                    <div className='filter-container'>
                        <span className={`link ${filter.link ? "on" : "off"}`} onClick={() => { handleSetFilter("link"); }}>Link</span>
                        <span className={`text ${filter.text ? "on" : "off"}`} onClick={() => { handleSetFilter("text"); }}>Text</span>
                        <span className={`pdf ${filter.pdf ? "on" : "off"}`} onClick={() => { handleSetFilter("pdf"); }}>PDF</span>
                    </div>
                </div>


                {/* <div>
                    <form onSubmit={handleSearch}>
                        <FormInput
                            class="query-input"
                            type="text"
                            value={query}
                            update={(value) => {
                                setQuery(value);
                                if (value === '') {
                                    setResults(null);
                                }
                            }}
                            placeholder={`search resources in ${pathwayList[pathwayId].name} ...`}
                        />
                    </form>
                    <button>Post</button>
                </div> */}

                <div className='blocks-container'>
                    {pathwayMyList && pathwayMyList[pathwayId] &&
                        <div className='block-container inner add-container' >
                            <button className="post-btn" onClick={() => { setPostIsOpen(!postIsOpen); setSuccess(false); }}>
                                <span className="material-symbols-outlined icon">{postIsOpen ? "remove" : "add"}</span>
                                <span>post a resource</span>
                            </button>
                            {postIsOpen &&
                                <div className='post-container inner'>
                                    {/* <p className="cancel" onClick={() => { setPostIsOpen(false); }}>Cancel</p> */}
                                    <Upload
                                        pathwayId={pathwayId}
                                        pathwayName={valueName}
                                        postSuccess={success}
                                        successMessage="Successfully posted a resource!"
                                        setPostSuccess={setSuccess}
                                        setPostIsOpen={setPostIsOpen}
                                    />
                                </div>
                            }
                        </div>
                    }
                    {pathwayResources && pathwayResources.length > 0 && pathwayResources.map((resource, index) => {
                        const jsx = (<div key={`${resource.id}_${index}`}>
                            {filter.link && resource.link_id && resource.is_active && <LinkBlock resource={resource} pathwayId={pathwayId} canDelete={pathwayMyList && pathwayMyList[pathwayId]} />}
                            {filter.pdf && resource.pdf_id && resource.is_active && <PdfBlock resource={resource} pathwayId={pathwayId} canDelete={pathwayMyList && pathwayMyList[pathwayId]} />}
                            {filter.text && resource.txt_id && resource.is_active && <TextBlock resource={resource} pathwayId={pathwayId} canDelete={pathwayMyList && pathwayMyList[pathwayId]} />}
                        </div>);
                        if (limit) {
                            while (index < limit) {
                                return jsx;
                            }
                        } else {
                            return jsx;
                        }
                    })}
                </div>

            </div>
        </>
    );
}