import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { store } from "./app/store.js";
// import { persistor, store } from "./app/store.js";
import reportWebVitals from './reportWebVitals';
import { GlobalContainer } from './context/globalContext';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react'; // Import PersistGate
import ReactGA from "react-ga4";

ReactGA.initialize("G-TLM8T059H6");

const SendAnalytics = () => {
  ReactGA.send({
    hitType: "pageview",
    page: window.location.pathname,
  });
};


const RootComponent = () => {
  const [analytics, setAnalytics] = useState(localStorage.getItem("cookieAnalytics") ? +localStorage.getItem("cookieAnalytics") : 0);
  useEffect(() => {
    if (analytics) {
      console.log("in analytics");
      reportWebVitals(SendAnalytics);
    }
  }, [analytics]);

  return (
    <React.StrictMode>

      <Provider store={store}>
        <GlobalContainer setAnalytics={setAnalytics} />
      </Provider>
    </React.StrictMode>

  );
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<RootComponent />);




// root.render(
//   // <React.StrictMode>
//   <Provider store={store}>
//     {/* <PersistGate loading={null} persistor={persistor}> */}
//     <GlobalContainer />
//     {/* </PersistGate> */}
//   </Provider>
//   // {/* </React.StrictMode> */ }
// );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
