import React, { useContext, useEffect, useRef, useState } from 'react';
import './Nav.scss';
import { GlobalContext } from '../../context/globalContext';
import { NavLink, useLocation } from 'react-router-dom';
import { FormInput } from '../../components/Form/Form';
import { performSearch } from '../../api/search';
import { selectCurrentUser } from '../../features/userSlice';
import { useDispatch, useSelector } from 'react-redux';
import { selectAllMyPathways } from '../../features/pathwaysSlice';
import { createCommunity } from '../../api/communities';
import { setAddCommunities } from '../../features/communitiesSlice';
import { isGuest } from '../../util/userLoggedIn';
import { LoginFirstBlock } from '../../components/PleaseLogin/PleaseLogin';

export default function Nav() {
    const {
        allCommunities,
        isSmallScreen,
        isNavOpen,
        setIsNavOpen,
        menuButtonRef,
    } = useContext(GlobalContext);

    const dispatch = useDispatch();
    const navRef = useRef(); // Create a ref for the nav

    const user = useSelector(selectCurrentUser);
    const [searchCommunityQuery, setSearchCommunityQuery] = useState("");
    const [communityResults, setCommunityResults] = useState(null);
    const myPathways = useSelector(selectAllMyPathways);

    const [createIsOpen, setCreateIsOpen] = useState(false);
    const [newCommunity, setNewCommunity] = useState({
        name: "",
        desc: ""
    });

    async function handleSearchCommunity(e) {
        e.preventDefault();
        if (user && user.userId && searchCommunityQuery) {
            const result = await performSearch({
                userId: user.userId,
                content: {
                    "query": searchCommunityQuery,
                    "includeCommunities": true,
                    "includePathways": false,
                    "includeCities": false,
                    "includeLinkBlocks": false,
                    "includeTxtBlocks": false,
                    "includePdfUploads": false,
                    "communityId": -1,
                    "k": 10
                }
            });
            setCommunityResults(result);
        }
    }

    async function handleCreateCommunity(e) {
        e.preventDefault();
        if (user && user.userId) {
            const result = await createCommunity({
                userId: user.userId,
                content: {
                    name: newCommunity.name, description: newCommunity.desc
                }
            });
            if (result && result.province_id > 0) {
                setCreateIsOpen(false);
                setNewCommunity({
                    name: "", desc: ""
                });
                dispatch(setAddCommunities({ [result.province_id]: result }));
            }
        }


    }

    useEffect(() => {
        if (isSmallScreen) {
            function handleClickOutside(event) {
                if (navRef.current && !navRef.current.contains(event.target) && (!menuButtonRef.current || !menuButtonRef.current.contains(event.target))) {
                    setIsNavOpen(false);
                    document.querySelector("html").style.overflow = "visible";
                }
            }

            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }
    }, [navRef, isSmallScreen, menuButtonRef]);

    function handleItemClicked(e) {
        if (isSmallScreen && e.target.closest('.item')) {
            setIsNavOpen(false);
            document.querySelector("html").style.overflow = "visible";
        }
    }

    return (
        <div ref={navRef} className={`nav-container ${isSmallScreen ? (isNavOpen ? 'open' : 'closed') : ''}`} onClick={handleItemClicked}>
            <Item icon="home" title="Home" class="" />
            <Item icon="explore" title="Explore Communities" class="home" />
            <div className="divider"></div>
            <div>
                <p className='community-title'>
                    <span>COMMUNITIES</span>
                    <button onClick={() => { setCreateIsOpen(!createIsOpen); }}><span className="material-symbols-outlined icon">
                        {createIsOpen ? "remove" : "add"}
                    </span>{createIsOpen ? "cancel" : "create"}</button>
                </p>
                {createIsOpen &&
                    <form onSubmit={handleCreateCommunity} className='create-community'>
                        {isGuest(user) && <LoginFirstBlock />}
                        <FormInput
                            type="text"
                            value={newCommunity.name}
                            placeholder="Name"
                            update={(value) => {
                                setNewCommunity(prevState => ({ ...prevState, name: value }));
                            }}
                            required={true}
                            disabled={isGuest(user)}
                        />
                        <FormInput
                            type="text"
                            value={newCommunity.desc}
                            placeholder="Description"
                            update={(value) => {
                                setNewCommunity(prevState => ({ ...prevState, desc: value }));
                            }}
                            required={true}
                            disabled={isGuest(user)}
                        />
                        <button type="submit" disabled={isGuest(user)}>CREATE</button>
                    </form>
                }
                <form onSubmit={handleSearchCommunity} >
                    <FormInput
                        type="text"
                        value={searchCommunityQuery}
                        placeholder="search communities ..."
                        update={(value) => {
                            setSearchCommunityQuery(value);
                            if (value === '') {
                                setCommunityResults(null);
                            }
                        }}
                        required={true}
                    />
                </form>
                <div className='block'>
                    {communityResults
                        ?
                        communityResults.map(i => {
                            return <Item icon="community" title={i.document.name} id={i.document.province_id} key={i.document.province_id} />;
                        })
                        :
                        allCommunities && Object.keys(allCommunities).map((i) => {
                            return <Item icon="community" title={allCommunities[i].name} id={i} key={i} />;
                        })
                    }
                </div>
            </div>
            <div>
                <p>MY LEARNING PATHWAYS</p>
                <div className='block'>
                    {myPathways && Object.keys(myPathways).length > 0 &&
                        Object.keys(myPathways).map((i) => {
                            const pathway = myPathways[i];
                            return <Item icon="route" title={pathway.name} id={i} key={i} communityId={pathway.province_id} />;
                        })
                    }
                </div>
            </div>
        </div>
    );
}

function Item(props) {
    const location = useLocation();
    let currentPath = "";
    if (props.icon === "explore") {
        currentPath = "/communities";
    } else if (props.icon === "home") {
        currentPath = "/";
    } else if (props.icon === "route") {
        currentPath = `pathway?community=${props.communityId}&pathway=${props.id}`;
    } else {
        currentPath = `/community?id=${encodeURIComponent(props.id)}&name=${encodeURIComponent(props.title)}`;
    }
    const queryParams = new URLSearchParams(location.search);
    const queryId = queryParams.get('id');
    const queryPathwayId = queryParams.get('pathway');
    let isSelected = false;

    if (props.icon === "home") {
        isSelected = location.pathname === "/";
    } else if (props.icon === "explore") {
        isSelected = location.pathname === "/communities";
    } else if (props.icon === "route") {
        isSelected = location.pathname === "/pathway" && queryPathwayId === props.id;
    } else {
        isSelected = location.pathname === "/community" && queryId === props.id;
    }
    const iconLib = {
        "community": "school",
        "book": "book_4",
        "webpage": "book",
        "video": "smart_display",
        "article": "library_books",
    };

    // const isActiveFunc = (match, location) => {
    //     const queryParams = new URLSearchParams(location.search);
    //     const queryId = queryParams.get('id');

    //     if (props.icon === "home") {
    //         return location.pathname === "/";
    //     } else {
    //         return location.pathname === "/community" && queryId === props.id;
    //     }
    // };


    return (
        <NavLink
            to={currentPath}
            className={`item flex ${props.class ? props.class : ''} ${isSelected ? 'selected' : ''}`}
        >
            <span className="material-symbols-outlined icon">{iconLib[props.icon] || props.icon}</span>
            <span>{props.title}</span>
        </NavLink>
    );
}