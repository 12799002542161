import avatar from "./media/avatar/avatar-placeholder.svg";

export const avatarPlaceholder = require("./media/avatar/avatar-placeholder.png");

export const socialMediaLogos = {
    facebook: require("./media/logo/facebook-logo.png"),
    twitter: require("./media/logo/twitter-logo.png"),
    linkedin: require("./media/logo/linkedin-logo.png"),
    // instagram: require("./media/logo/instagram-logo.png"),
};

export const unfoldIcon = require("./media/icon/unfold.png");