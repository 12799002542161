import React, { useContext, useEffect } from 'react';
import './HomePage.scss';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import { selectCurrentUser } from '../../features/userSlice';
import { asyncFetchRecommendedPathways, selectAllMyPathways, selectAllRecommendedPathways, selectAllRecommendedPathwaysStatus } from '../../features/pathwaysSlice';
import { asyncFetchAllCommunitiesAndResources, selectAllCommunitiesAndResources, selectAllCommunitiesAndResourcesStatus } from '../../features/communitiesSlice';
import PathwayBlock from '../../components/Blocks/PathwayBlock';
import { GlobalContext } from '../../context/globalContext';

export default function HomePage() {
    const dispatch = useDispatch();
    const user = useSelector(selectCurrentUser);
    const recommendedPathways = useSelector(selectAllRecommendedPathways);
    const recommendedPathwaysStatus = useSelector(selectAllRecommendedPathwaysStatus);
    const communitiesAndResources = useSelector(selectAllCommunitiesAndResources);
    const communitiesAndResourcesStatus = useSelector(selectAllCommunitiesAndResourcesStatus);
    const pathwayMyList = useSelector(selectAllMyPathways);

    useEffect(() => {
        if (user && user.userId) {
            dispatch(asyncFetchRecommendedPathways(user.userId));
            // dispatch(asyncFetchAllCommunitiesAndResources({ userId: user.userId, count: 4 }));
        }
    }, [dispatch, user]);

    return (
        <>
            <Helmet>
                <title></title>
                <meta name='description' content='' />
                <meta name='keywords' content='' />
            </Helmet>
            <div className={"home-page-container"}>
                {recommendedPathways && recommendedPathways.length > 0 &&
                    <div className='section'>
                        <h2 className='h-title title-center'>Recommended Pathways for You</h2>
                        <div className='negative-margin'>
                            {recommendedPathways.map(i => {
                                const pathway = i.pathway_details;
                                return (
                                    <PathwayBlock
                                        resource={{ id: pathway.pathway_id, name: pathway.name, communityId: pathway.province_id, description: pathway.description }}
                                        // canDelete={pathwayMyList && pathwayMyList[pathway.pathway_id]}
                                        canDelete={false}
                                        key={pathway.pathway_id}
                                    />
                                );
                            })}
                        </div>
                    </div>
                }
                {/* <div className='section'>
                    <h2 className='h-title'>Popular Communities</h2>
                </div> */}
            </div>
        </>
    );
}