import { axiosCustom } from "./axiosCustom";


export const listAllLearningPathways = async (communityId) => {
    try {
        const response = await axiosCustom.get(`/provinces/${communityId}/pathways`);
        return response.data;
    } catch (error) {
        console.error("Error fetching learning pathways:", error);
        throw error;
    }
};

export const listAllMyLearningPathways = async (userId) => {
    try {
        const response = await axiosCustom.get(`/users/details/pathways?user_id=${userId}`);
        return response.data;
    } catch (error) {
        console.error("Error fetching learning pathways:", error);
        throw error;
    }
};

export const listAllRecommendedLearningPathways = async (userId) => {
    try {
        const response = await axiosCustom.post(`/users/recommendations?user_id=${userId}`, { k: 10 });
        return response.data;
    } catch (error) {
        console.error("Error fetching learning pathways:", error);
        throw error;
    }
};

export const createLearningPathway = async ({ userId, content: { title, description, communityId, subjectId } }) => {
    console.log({ userId, title, description, communityId });
    try {
        // const response = await axiosCustom.post(`/create_pathway?title=${title}&description=${description}&user_id=${user_id}&community_id=${community_id}`);

        const response = await axiosCustom.post(`/pathways?user_id=${userId}`,
            {
                name: title,
                description,
                province_id: communityId,
                city_id: subjectId || 1,
            }
        );
        return response.data;
    } catch (error) {
        console.error('Error creating learning pathway:', error);
        throw error;
    }
};

// export const createLearningPathway = async (content) => {
//     try {
//         const response = await axiosCustom.post(`/create_pathway`, content);
//         return response.data;
//     } catch (error) {
//         console.error('Error creating learning pathway:', error);
//         throw error;
//     }
// };

export const getLearningPathwayById = async (pathwayId) => {
    try {
        const response = await axiosCustom.get(`/pathways/${pathwayId}`);
        return response.data;
    } catch (error) {
        console.error('Error fetching learning pathway:', error);
        throw error;
    }
};

export const updateLearningPathway = async ({ pathwayId, content: { name, description, communityId, cityId } }) => {
    try {
        const response = await axiosCustom.put(`/pathways/${pathwayId}`, {
            name, description, province_id: communityId, city_id: cityId || 0
        });
        return response.data;
    } catch (error) {
        console.error('Error updating learning pathway:', error);
        throw error;
    }
};

export const deleteLearningPathway = async (pathwayId) => {
    try {
        const response = await axiosCustom.delete(`/pathways/${pathwayId}`);
        return response.data;
    } catch (error) {
        console.error('Error deleting learning pathway:', error);
        throw error;
    }
};