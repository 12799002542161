import { axiosCustom } from "./axiosCustom";


export const performSearch = async ({ userId, content: { query, includeCommunities, includeCities, includePathways, includeLinkBlocks, includeTxtBlocks, includePdfUploads, k, communityId } }) => {
    try {
        console.log("innnn");
        console.log(userId, query);
        console.log(communityId);
        const response = await axiosCustom.post(`/similarity_search?user_id=${userId}`, {
            query,
            "include_provinces": includeCommunities || false,
            "include_cities": includeCities || false,
            "include_pathways": includePathways || false,
            "include_link_blocks": includeLinkBlocks || false,
            "include_txt_blocks": includeTxtBlocks || false,
            "include_pdf_uploads": includePdfUploads || false,
            "k": k || 35,
            "province_id": communityId
        });
        console.log(response);
        return response.data;
    } catch (error) {
        console.error('Error performing search:', error);
        throw error;
    }
};
