import React, { useContext } from 'react';
import './Footer.scss';
import { NavLink, useLocation } from 'react-router-dom';
import { socialMediaLogos } from '../../assets/assetsExports';
import { GlobalContext } from '../../context/globalContext';

export default function Footer() {
    const { isSmallScreen } = useContext(GlobalContext);
    const location = useLocation();
    return (
        <div className={`footer-container flex ${!isSmallScreen && !location.pathname.includes("/pdf-viewer/") && !location.pathname.includes("/careers-challenge") && !location.pathname.includes("/policy/") && "placeholder"} ${location.pathname.includes("/careers-challenge") && "challenge"}`}>
            <p className='logo'>Tecademia</p>
            <div className='social-media flex'>
                {Object.keys(socialMediaLogos).map((key) => {
                    return <img src={socialMediaLogos[key]} alt={key} key={key} />;
                })}
            </div>
            {/* <div className='section flex'>
                <NavLink className="item" to="/about-us">About Us</NavLink>
                <NavLink to="/contact-us">Contact Us</NavLink>
            </div> */}
            <div className='section flex'>
                <NavLink className="item" to="/policy/terms-and-conditions">Terms & Conditions</NavLink>
                <NavLink className="item" to="/policy/privacy-policy">Privacy Policy</NavLink>
                <NavLink to="/policy/cookies-policy">Cookies Policy</NavLink>
            </div>
            <p className='copyright'>&copy; 2023 Note Sharing App</p>
        </div>
    );
}