import React from 'react';
import './Form.scss';

/**
 * 
 * @param {*} props | class? id? label? img? type=string value= placeholder? update() required? disabled?
 * @returns 
 */
export function FormInput(props) {
    return (
        <label className={`${props.class || ""} form-txt form-item`} id={props.id && props.id}>
            {props.label && <p>{props.label}</p>}
            {props.img && <img src={props.img} />}
            <input
                className="field"
                type={props.type}
                value={props.value}
                placeholder={props.placeholder ? props.placeholder : ""}
                onChange={(e) => { props.update(e.target.value); }}
                required={props.required ? true : false}
                disabled={props.disabled || false}
            />
        </label >
    );
}

export function FormTextarea(props) {
    return (
        <label className={`${props.class || ""} form-txt form-item`} id={props.id && props.id}>
            {props.label && <p>{props.label}</p>}
            {props.img && <img src={props.img} />}
            <textarea
                className="field"
                value={props.value}
                onChange={(e) => { props.update(e.target.value); }}
                required={props.required ? true : false}
                placeholder={props.placeholder ? props.placeholder : ""}
                disabled={props.disabled || false}
            />
        </label >
    );
}

export function FormCheckbox(props) {
    return (
        <label className={`${props.class || ""} form-checkbox`} id={props.id && props.id}>
            <input
                type="checkbox"
                checked={props.value}
                onChange={(e) => {
                    e.preventDefault();
                    props.update(e.target.checked);
                }}
                disabled={props.disabled || false}
            />
            {props.value
                ?
                <svg t="1675354022969" className="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2672" width="200" height="200"><path d="M832 96.00086l-640 0c-52.9288 0-96.00086 43.07206-96.00086 96.00086l0 640c0 52.92708 43.07206 95.99914 96.00086 95.99914l640 0c52.92708 0 95.99914-43.07206 95.99914-95.99914l0-640C928.00086 139.0712 884.9288 96.00086 832 96.00086zM727.231286 438.432254 471.00766 697.439161c-0.063647 0.063647-0.192662 0.096331-0.25631 0.192662-0.096331 0.063647-0.096331 0.192662-0.192662 0.25631-2.048757 1.983389-4.575729 3.19957-6.944443 4.544765-1.183497 0.672598-2.143368 1.696116-3.392232 2.176052-3.839484 1.536138-7.904314 2.33603-11.967424 2.33603-4.095794 0-8.224271-0.799892-12.096439-2.399677-1.279828-0.543583-2.303346-1.632469-3.519527-2.303346-2.368714-1.343475-4.832039-2.528692-6.880796-4.544765-0.063647-0.063647-0.096331-0.192662-0.159978-0.25631-0.063647-0.096331-0.192662-0.096331-0.25631-0.192662l-126.016611-129.503454c-12.320065-12.672705-12.032791-32.928047 0.639914-45.248112 12.672705-12.287381 32.895364-12.063755 45.248112 0.639914l103.26354 106.112189 233.279613-235.808305c12.416396-12.576374 32.704421-12.672705 45.248112-0.25631C739.520387 405.631501 739.647682 425.888563 727.231286 438.432254z" fill="hsl(0, 0%, 50%)" p-id="2673"></path></svg>
                :
                <svg t="1675354051984" className="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2826" width="200" height="200"><path d="M832 928.00086l-640 0c-52.9288 0-96.00086-43.07206-96.00086-95.99914l0-640c0-52.9288 43.07206-96.00086 96.00086-96.00086l640 0c52.92708 0 95.99914 43.07206 95.99914 96.00086l0 640C928.00086 884.9288 884.9288 928.00086 832 928.00086zM192 160.00086c-17.632039 0-32.00086 14.368821-32.00086 32.00086l0 640c0 17.664722 14.368821 31.99914 32.00086 31.99914l640 0c17.664722 0 31.99914-14.336138 31.99914-31.99914l0-640c0-17.632039-14.336138-32.00086-31.99914-32.00086L192 160.00086z" fill="hsl(0, 0%, 50%)" p-id="2827"></path></svg>
            }
            <p>{props.label}</p>
        </label >
    );
}

/**
 * props.class? label? img? selectedValue update() options
 */
export function FormSelect(props) {
    return (
        <label className={`${props.class || ""} form-select form-item`} id={props.id && props.id}>
            {props.label && <p>{props.label}</p>}
            {props.img && <img src={props.img} />}
            <select value={props.selectedValue} onChange={(e) => { props.update(e.target.value); }} disabled={props.disabled || false}>
                <option value={props.choose.value}>{props.choose.text}</option>
                {props.options && props.options.map((i, index) => {
                    if (i && props.type === "province") {
                        if (i.title) {
                            return (<option value={i.province_id} key={`${i.province_id}${index}`}>{i.title}</option>);
                        } else if (i.name) {
                            return (<option value={i.province_id} key={`${i.province_id}${index}`}>{i.name}</option>);
                        }
                    } else if (i && props.type === "pathway") {
                        if (i.title) {
                            return (<option value={i.pathway_id} key={`${i.pathway_id}${index}`}>{i.title}</option>);
                        } else if (i.name) {
                            return (<option value={i.pathway_id} key={`${i.pathway_id}${index}`}>{i.name}</option>);
                        }
                    }
                })}
                {props.new && props.new.value && <option value={props.new.value}>{props.new.text}</option>}
            </select>
        </label >
    );
}