import React, { useContext, useEffect, useState } from 'react';
import './Chatbot.scss';
import { deleteChatSession, getAllSessionMessages, getAllUserSessions, postChatMessage, startChat, updateSessionName } from '../../api/chat';
import { useSelector } from 'react-redux';
import { selectCurrentUser } from '../../features/userSlice';
import { GlobalContext } from "../../context/globalContext";

export default function Chatbot() {
    const [prompt, setPrompt] = useState('');
    const [open, setOpen] = useState(true);
    const [conversation, setConversation] = useState([]);
    const [error, setError] = useState("");
    const userId = useSelector(selectCurrentUser).userId;
    const [sessionId, setSessionId] = useState(null);
    const [waiting, setWaiting] = useState(false);
    const [userSessions, setUserSessions] = useState({});
    const [sessionName, setSessionName] = useState("New Chat");
    const [sessionNameTemp, setSessionNameTemp] = useState("New Chat");
    const [showHistory, setShowHistory] = useState(false);
    const [editSessionName, setEditSessionName] = useState(false);
    const [historyId, setHistoryId] = useState(0);
    const { isMobile } = useContext(GlobalContext);

    async function handleSubmitPrompt(e) {
        e.preventDefault();
        try {
            if (sessionId) {
                setConversation(prev => [...prev, prompt]);
                setPrompt("");
                setWaiting(true);
                const response = await postChatMessage(sessionId, prompt);
                setWaiting(false);
                // Scroll the ".chatbot-conversation" to the bottom
                const chatbotConversation = document.querySelector('.chatbot-conversation');
                chatbotConversation.scrollTop = chatbotConversation.scrollHeight;
                if (response.latest_response.assistant) {
                    setConversation(prev => [...prev, response.latest_response.assistant]);
                }
            }
        } catch (error) {
            console.error(error);
            setError("Failed to submit the messsage...");
        }
    }

    function handleNewChat(e) {
        e.preventDefault();
        if (userId) {
            setHistoryId(0);
            startAChat(userId);
        }
    }

    useEffect(() => {
        if (userId) {
            (async () => {
                const response = await getAllUserSessions(userId);
                if (response && response.length > 0) {
                    const sessionObj = response.reduce((acc, session) => {
                        acc[session.session_id] = { ...session };
                        delete acc[session.session_id].session_id;
                        return acc;
                    }, {});
                    setUserSessions(sessionObj);
                }
            })();
        }
    }, [userId]);

    useEffect(() => {
        if (historyId > 0) {
            (async () => {
                const response = await getAllSessionMessages(historyId);
                if (response.conversation) {
                    const parsedData = JSON.parse(response.conversation);
                    const messagesArray = parsedData.messages.map(message => {
                        return message.user || message.assistant;
                    });
                    setConversation(messagesArray);
                }
            })();
        }
    }, [historyId]);

    async function startAChat(userId) {
        const response = await startChat(userId, "New Chat");
        if (response.session_id > 0) {
            setSessionId(response.session_id);
            setSessionName("New Chat");
            setUserSessions(prev => ({ ...prev, [response.session_id]: { session_name: response.session_name } }));
            setPrompt("");
            setConversation("");
            return true;
        } else {
            setError("Failed to start a chat... Please try again later.");
            return false;
        }
    }

    async function handleChangeSessionName(e) {
        e.preventDefault();
        const response = await updateSessionName({ userId, sessionId }, sessionName);
        if (response.message === "Chat session name updated successfully") {
            setEditSessionName(false);
            setSessionNameTemp(sessionName);
            setUserSessions(prev => ({ ...prev, [sessionId]: { ...prev[sessionId], session_name: sessionName } }));

        } else {
            setSessionName(sessionNameTemp);
        }
    }




    return (
        <div className={"chatbot-container"}>
            {open ?
                <div className='chatbot-open flex'>
                    {showHistory &&
                        <div className='chatbot-history'>
                            <p className='flex' style={{ justifyContent: "space-between", alignItems: "center" }}><b>History</b>{isMobile && <span className="material-symbols-outlined" style={{ fontWeight: "200" }} onClick={() => { setShowHistory(false); }}>close</span>}</p>
                            <br />
                            {userSessions && Object.keys(userSessions).length > 0 && Object.entries(userSessions).reverse().map(([id, session]) => {
                                return (
                                    <HistorySession name={session.session_name} id={id} userId={userId} setHistoryId={setHistoryId} setSessionId={setSessionId} setSessionName={setSessionName} setShowHistory={setShowHistory} isMobile={isMobile} />
                                );
                            })}
                        </div>
                    }
                    <div className='chatbot-chat'>
                        <div className='chatbot-nav'>
                            <div className='flex'>
                                <button className='btn-history' onClick={() => { setShowHistory(!showHistory); }}><span className="material-symbols-outlined">
                                    history
                                </span></button>
                                {sessionId &&
                                    <button className='btn-new' onClick={handleNewChat}>
                                        <span className="material-symbols-outlined">
                                            edit_square
                                        </span>
                                        New Chat
                                    </button>
                                }
                            </div>
                            {sessionId &&
                                <div
                                    onDoubleClick={() => { if (!isMobile) setEditSessionName(true); }}
                                    onTouchStart={(e) => { if (isMobile) { e.persist(); setTimeout(() => { if (e.touches.length > 0) setEditSessionName(true); }, 1000); } }}
                                    onTouchEnd={() => { if (isMobile) clearTimeout(); }}
                                >
                                    {editSessionName ? <form onSubmit={handleChangeSessionName}><input className="input-session-name" value={sessionName} onChange={(e) => { setSessionName(e.target.value); }} /></form> : <p className='input-session-name-p'>{sessionName}</p>}
                                </div>
                            }
                            <button className='btn-hide' onClick={() => { setOpen(false); }}>{!isMobile && "Hide"}<span className="material-symbols-outlined">
                                hide
                            </span></button>
                        </div>
                        {
                            sessionId ?
                                <>
                                    <div className='chatbot-conversation'>
                                        {conversation && conversation.length > 0 ? conversation.map((i, index) => {
                                            return (
                                                <div>
                                                    <p><b>{index % 2 === 0 ? "You" : "Bot"}</b></p><p>{i}</p>
                                                </div>
                                            );
                                        })
                                            :
                                            <p style={{ opacity: "0.5" }}>Ask me a question...</p>
                                        }
                                        {waiting && <p>...</p>}
                                        {error && <p>{error}</p>}
                                    </div>
                                    <form className='chatbot-prompt' onSubmit={handleSubmitPrompt}>
                                        <input type='text' value={prompt} onChange={(e) => { setError(""); setPrompt(e.target.value); }} />
                                        <button type='submit'>Submit</button>
                                    </form>
                                </>
                                :
                                <button className='btn-new-mid' onClick={handleNewChat}>
                                    <span className="material-symbols-outlined">
                                        edit_square
                                    </span>
                                    New Chat
                                </button>
                        }
                    </div>
                </div>
                :
                <div className='chatbot-close'>
                    <button onClick={() => { setOpen(true); }}> <span className="material-symbols-outlined">
                        edit_square
                    </span>Chat with me</button>
                </div>
            }
        </div>
    );
}

function HistorySession({ name, id, userId, setHistoryId, setSessionId, setSessionName, setShowHistory, isMobile }) {
    const [present, setPresent] = useState(true);
    async function handleDeleteSession(sessionId) {
        const response = await deleteChatSession(userId, sessionId);
        if (response.message === "Chat session deleted successfully") {
            setPresent(false);
        }
    }

    return (
        <>
            {present &&
                <div className='history-session flex' onClick={() => { setHistoryId(id); setSessionId(id); setSessionName(name); if (isMobile) { setShowHistory(false); } }}>
                    <p>{name}</p>
                    <button onClick={() => { handleDeleteSession(id); }}><span className="material-symbols-outlined">delete</span></button>
                </div>
            }
        </>
    );
}