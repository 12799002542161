import React, { useEffect, useState } from 'react';
import './PdfViewerPage.scss';
import { Helmet } from 'react-helmet';
import { Worker, Viewer } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import { useLocation, useParams } from 'react-router-dom';
import axios from 'axios';

export default function PdfViewerPage() {
    const { fileName } = useParams();

    const [selectedPdf, setSelectedPdf] = useState(null);

    const convertToUrl = (path) => {
        // const bucketPath = 'gs://';
        const cloudPath = 'https://storage.googleapis.com/tecademia1-mvp4-pdfs/';
        return cloudPath + path + ".pdf";
        // return path.replace(bucketPath, cloudPath).replace(/ /g, '%20');
    };

    useEffect(() => {
        if (fileName) {
            (async () => {
                const response = await axios.get(convertToUrl(fileName), { responseType: 'blob' });
                const file = new Blob([response.data], { type: 'application/pdf' });
                setSelectedPdf(URL.createObjectURL(file));
            })();
        }
    }, [fileName]);

    return (
        <>
            <Helmet>
                <title>{fileName || ""} | Tecademia</title>
                <meta name='description' content={fileName || ""} />
                <meta name='keywords' content={fileName || ""} />
            </Helmet>
            <div className={"pdf-viewer-page-container"}>
                {/* <a className="download" href={convertToUrl(fileName)} target='_blank'>Download PDF</a> */}
                {selectedPdf &&
                    <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.9.179/build/pdf.worker.min.js">
                        <Viewer fileUrl={selectedPdf} />
                    </Worker>
                }
            </div>
        </>
    );
}