import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { ActionBlock, DeleteBlock, canClick } from './Blocks';
import { FormInput, FormTextarea } from '../Form/Form';

export default function PathwayBlock(props) {
    const [isDeleted, setIsDeleted] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [valueDesc, setValueDesc] = useState(props.resource.description);
    const [valueName, setValueName] = useState(props.resource.name);
    const [restoredValues, setRestoredValues] = useState(null);

    useEffect(() => {
        if (isEditing) {
            setRestoredValues({ name: valueName, desc: valueDesc });
        }
    }, [isEditing]);

    if (isDeleted) {
        return null;
    }

    return (
        <div
            onClick={(e) => { if (canClick(e) && !isEditing) window.location.href = `/pathway?community=${props.resource.communityId}&pathway=${props.resource.id}`; }}
            className='pathway-block-container block-container'
            key={props.resource.id}
        >
            {/* <div onClick={(e) => { if (e.target.className !== 'material-symbols-outlined icon') window.location.href = `/pathway?community=${props.resource.communityId}&pathway=${props.resource.id}`; }} className='pathway-block-container block-container' key={props.resource.id}> */}
            <div className='block-title-container'>
                <p className='title flex'>
                    <span className="material-symbols-outlined icon">route</span>
                    {isEditing ? <FormInput value={valueName} update={setValueName} required={true} /> : <span>{valueName}</span>}
                </p>
                <div className='right'>
                    {props.canDelete &&
                        <ActionBlock
                            type="pathway"
                            id={props.resource.id}
                            setIsDeleted={setIsDeleted}
                            setIsEditing={setIsEditing}
                            restoreValue={() => { setValueDesc(restoredValues.desc); setValueName(restoredValues.name); }}
                            content={{
                                name: valueName.trim(),
                                description: valueDesc.trim(),
                                province_id: props.resource.communityId,
                                city_id: props.resource.cityId,
                            }}
                        />
                    }
                    <p className='tag pathway'>Pathway</p>
                </div>
            </div>
            {isEditing
                ? < FormTextarea value={valueDesc} update={setValueDesc} class="description-input" />
                : <p>{valueDesc}</p>
            }

        </div>
    );
}