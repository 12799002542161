import React, { useEffect } from 'react';
import { Provider, useDispatch, useSelector } from 'react-redux';
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "../config/firebaseConfig";
import './App.css';
import AppRoutes from './AppRoutes';
import { selectIsUserIdFetchedStatus, setUserIdFetched, asyncFetchUserId, asyncFetchUser, setUser, asyncLoginWithGoogle } from '../features/userSlice';
import { clearMyPathway } from '../features/pathwaysSlice';

function App() {
  const dispatch = useDispatch();
  const status = useSelector(selectIsUserIdFetchedStatus);

  useEffect(() => {
    (async () => {
      await dispatch(setUserIdFetched(false));
    })();
  }, []);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      try {
        if (user && !status) {
          await dispatch(asyncLoginWithGoogle(user));
        } else if (!user) {
          dispatch(setUser({
            userId: 1, username: "Guest", displayName: "Guest"
          }));
          dispatch(clearMyPathway());
        }
      } catch (error) {
        console.error("Error updating user state when onAuthStateChanged:" + error);
      }
    });

    return () => unsubscribe();
  }, [dispatch, status]);

  return (
    <div className="App">
      <AppRoutes />
    </div>
  );
}

export default App;
