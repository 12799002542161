import React, { useContext, useEffect } from 'react';
import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";
import Header from '../layouts/Header/Header';
import Footer from '../layouts/Footer/Footer';
import Nav from '../layouts/Nav/Nav';
import AboutUsPage from '../pages/AboutUsPage/AboutUsPage';
import ContactUsPage from '../pages/ContactUsPage/ContactUsPage';
import { CookiesPolicy, PrivacyPolicy, TermsAndConditions } from '../pages/PrivacyPages/PrivacyPages';
import UploadPage from '../pages/UploadPage/UploadPage';
import LearningPathwayPage, { LearningPathway } from '../pages/LearningPathwayPage/LearningPathwayPage';
import CommunityPage from '../pages/CommunityPage/CommunityPage';
import { CommunitySkeletonLoaderPage } from '../components/Loaders/SkeletonLoaders/SkeletonLoaders';
import SearchResultPage from '../pages/SearchResultPage/SearchResultPage';
import { GlobalContext } from '../context/globalContext';
import PdfViewerPage from '../pages/PdfViewerPage/PdfViewerPage';
import ExploreCommunitiesPage from '../pages/ExploreCommunities/ExploreCommunitiesPage';
import HomePage from '../pages/HomePage/HomePage';
import CareersChallengePage from '../pages/CareersChallengePage/CareersChallengePage';
import BannerCookie from '../layouts/Banner/Cookie';
import Chatbot from '../components/Chatbot/Chatbot';


function MainContent() {
    const { isSmallScreen } = useContext(GlobalContext);
    const location = useLocation();

    return (
        <>
            {!location.pathname.includes("/careers-challenge") && <Header />}
            <main className={`flex ${location.pathname.includes("/careers-challenge") && "challenge"}`}>
                {!location.pathname.includes("/pdf-viewer/") && !location.pathname.includes("/careers-challenge") && !location.pathname.includes("/policy/") && <Nav />}
                {!isSmallScreen && !location.pathname.includes("/pdf-viewer/") && !location.pathname.includes("/careers-challenge") && !location.pathname.includes("/policy/") && <div className='nav-placeholder'></div>}
                <div className="content">
                    <Routes>
                        <Route path="/about-us" element={<AboutUsPage />} />
                        <Route path="/contact-us" element={<ContactUsPage />} />
                        <Route path="/post" element={<UploadPage />} />
                        <Route path="/community" element={<CommunityPage />} />
                        <Route path="/search" element={<SearchResultPage />} />

                        {/* testing placeholders*/}
                        {/* <Route path="/community-placeholder" element={<CommunitySkeletonLoaderPage />} /> */}

                        <Route path="/policy/privacy-policy" element={<PrivacyPolicy />} />
                        <Route path="/policy/terms-and-conditions" element={<TermsAndConditions />} />
                        <Route path="/policy/cookies-policy" element={<CookiesPolicy />} />
                        <Route path="/pdf-viewer/:fileName" element={<PdfViewerPage />} />
                        <Route path="/pathway" element={<LearningPathwayPage />} />
                        <Route path="/communities" element={<ExploreCommunitiesPage />} />
                        <Route path="/careers-challenge" element={<CareersChallengePage />} />
                        <Route path="/" element={<HomePage />} />
                    </Routes>
                </div>
            </main>
            <Chatbot />
            <BannerCookie />
            <Footer />
        </>
    );
}

export default function AppRoutes() {
    return (
        <BrowserRouter>
            <ScrollToTop />
            <MainContent />
        </BrowserRouter>
    );
}

const ScrollToTop = () => {
    const location = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location]);

    return null;
};