import React, { createContext, useContext, useState } from "react";
import { deactivateLinkBlock, deactivatePathwayBlock, deactivatePdfBlock, deactivateTextBlock, updateCommunityBlock, updateLinkBlock, updatePathwayBlock, updatePdfBlock, updateTextBlock } from '../../api/resources';
import { useDispatch, useSelector } from 'react-redux';
import { selectCurrentUser } from '../../features/userSlice';
import { removePathway } from '../../features/pathwaysSlice';

// const UserContext = createContext();

// export function useUser() {
//     return useContext(UserContext);
// }

// export function UserProvider({ children }) {
//     const user = useSelector(selectCurrentUser);
//     return (
//         <UserContext.Provider value={user}>
//             {children}
//         </UserContext.Provider>
//     );
// }

export function ActionBlock({ type, id, setIsDeleted, setIsEditing, restoreValue, handleConfirmEdit, content, community = null }) {
    const [editIsOpen, setEditIsOpen] = useState(false);
    const [deleteIsOpen, setDeleteIsOpen] = useState(false);
    return (
        <div className="action-container">
            <EditBlock type={type} id={id} setIsEditing={setIsEditing} isOpen={editIsOpen} setIsOpen={setEditIsOpen} setOtherIsOpen={setDeleteIsOpen} restoreValue={restoreValue} handleConfirmEdit={handleConfirmEdit} content={content} />
            <DeleteBlock type={type} id={id} setIsDeleted={setIsDeleted} setIsEditing={setIsEditing} isOpen={deleteIsOpen} setIsOpen={setDeleteIsOpen} setOtherIsOpen={setEditIsOpen} community={community} />
        </div>
    );
}


export function DeleteBlock({ type, id, setIsDeleted, setIsEditing, community, isOpen, setIsOpen, setOtherIsOpen, }) {
    // const user = useUser();
    const user = useSelector(selectCurrentUser);
    const dispatch = useDispatch();
    // const [isOpen, setIsOpen] = useState(false);

    async function handleDeleteBlock(e) {
        e.preventDefault();
        e.nativeEvent.stopImmediatePropagation();

        switch (type) {
            case "link":
                const linkResponse = await deactivateLinkBlock({ userId: user.userId, id });
                if (linkResponse.status === "Link block deactivated") {
                    setIsDeleted(true);
                }
                break;
            case "text":
                const textResponse = await deactivateTextBlock({ userId: user.userId, id });
                if (textResponse.txt_id > 0) {
                    setIsDeleted(true);
                }
                break;
            case "pdf":
                const pdfResponse = await deactivatePdfBlock({ userId: user.userId, id });
                if (pdfResponse.status === "Pdf block deactivated") {
                    setIsDeleted(true);
                }
                break;
            case "pathway":
                const pathwayResponse = await deactivatePathwayBlock({ userId: user.userId, id });
                console.log(pathwayResponse);
                if (pathwayResponse.message === "Pathway deactivated") {
                    console.log(setIsDeleted);
                    if (setIsDeleted) {
                        setIsDeleted(true);
                        dispatch(removePathway(id));
                    } else {
                        console.log(community);
                        if (community) {
                            window.location.href = `/community?id=${community.id}&name=${community.name.replace(/ /g, "%20")}`;
                        }
                    }
                }
                break;
            default:
                console.error("Invalid block type");
        }
    }

    return (
        <div className='delete-action-container' onClick={() => { setOtherIsOpen(false); setIsEditing(false); }}>

            {isOpen ?
                <div className='confirm-action'>
                    <button className="confirm no-click" onClick={handleDeleteBlock}>Confirm</button>
                    <button className="cancel no-click" onClick={(e) => { e.preventDefault(); e.nativeEvent.stopImmediatePropagation(); setIsOpen(false); }}>Cancel</button>
                </div>
                :
                <span className="material-symbols-outlined icon delete no-click" onClick={() => { setIsOpen(true); }} >
                    delete
                </span>
            }
        </div>
    );
}

export function EditBlock({ type, id, setIsEditing, community = null, isOpen, setIsOpen, setOtherIsOpen, restoreValue, content }) {
    // const user = useUser();
    const user = useSelector(selectCurrentUser);
    async function handleConfirmEdit(e) {
        e.preventDefault();
        e.nativeEvent.stopImmediatePropagation();

        if (!user) {
            console.error("user is undefined");
            return;
        }

        try {
            switch (type) {
                case "link":
                    const responseLink = await updateLinkBlock({
                        id,
                        userId: user.userId,
                        content
                    });
                    if (+responseLink.link_id === +id) {
                        setIsEditing(false);
                        setIsOpen(false);
                    }
                    break;
                case "text":
                    if (!content.title.trim()) return;
                    const responseText = await updateTextBlock({
                        id,
                        userId: user.userId,
                        content
                    });
                    if (+responseText.txt_id === +id) {
                        setIsEditing(false);
                        setIsOpen(false);
                    }
                    break;
                case "pdf":
                    const responsePdf = await updatePdfBlock({
                        id,
                        userId: user.userId,
                        content
                    });
                    if (+responsePdf.pdf_id === +id) {
                        setIsEditing(false);
                        setIsOpen(false);
                    }
                    break;
                case "pathway":
                    const responsePathway = await updatePathwayBlock({
                        id,
                        userId: user.userId,
                        content
                    });
                    if (+responsePathway.pathway_id === +id) {
                        setIsEditing(false);
                        setIsOpen(false);
                    }
                    break;
                case "pathway":
                    const responseCommunity = await updateCommunityBlock({
                        id,
                        userId: user.userId,
                        content
                    });
                    if (+responseCommunity.province_id === +id) {
                        setIsEditing(false);
                    }
                    break;
                default:
                    console.error("Invalid block type");
            }
        } catch (error) {
            console.error(error);
        }
    }


    return (
        <div className='edit-action-container' onClick={() => { setOtherIsOpen(false); }}>
            {isOpen ?
                <div className='confirm-action'>
                    <button className="no-click confirm" onClick={handleConfirmEdit}>confirm</button>
                    <button className="no-click cancel" onClick={(e) => { e.preventDefault(); e.nativeEvent.stopImmediatePropagation(); restoreValue(); setIsEditing(false); setIsOpen(false); }}>cancel</button>
                </div>
                :
                <span className="material-symbols-outlined icon no-click" onClick={() => { setIsOpen(true); setIsEditing(true); }} >
                    edit_note
                </span>
            }
        </div>
    );
}

export function canClick(e) {
    let target = e.target;
    while (target) {
        if (target.classList.contains("no-click")) {
            return false;
        }
        if (target.classList.contains("content")) {
            return true;
        }
        target = target.parentNode;
    }
    return true;
}