import React, { useEffect, useState } from 'react';
import { Worker, Viewer } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import axios from 'axios';
import { NavLink } from 'react-router-dom';
import { ActionBlock, DeleteBlock, canClick } from './Blocks';
import { FormInput, FormTextarea } from '../Form/Form';

export default function PdfBlock(props) {
    const [isDeleted, setIsDeleted] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [valueDesc, setValueDesc] = useState(props.resource.description);
    const [valueTitle, setValueTitle] = useState(props.resource.title);
    const [restoredValues, setRestoredValues] = useState(null);

    useEffect(() => {
        if (isEditing) {
            setRestoredValues({ title: valueTitle, desc: valueDesc });
        }
    }, [isEditing]);

    if (isDeleted) {
        return null;
    }

    return (
        <div
            className={`pdf-block-container block-container`}
            onClick={(e) => {
                if (canClick(e) && !isEditing)
                    window.open(`/pdf-viewer/${props.resource.file_path.replace("gs://", "").replace(/ /g, '%20').replace(".pdf", "")}`, '_blank');
            }}
            key={props.resource.id}
        >
            {/* // <NavLink
        //     className={`pdf-block-container block-container`}
        //     to={`/pdf-viewer/${props.resource.file_path.replace("gs://", "").replace(/ /g, '%20').replace(".pdf", "")}`}
        //     target='_blank'
        //     key={props.resource.id}
        // > */}
            {/* <a className={`pdf-block-container block-container`} href={convertToUrl(props.resource.file_path)} target='_blank' rel='noopener noreferrer'> */}
            <div className='block-title-container'>
                {props.resource.title && isEditing
                    ? <FormInput value={valueTitle} update={setValueTitle} required={true} />
                    : <p className='title'>
                        <span className="material-symbols-outlined icon">content_copy</span>
                        {valueTitle}
                    </p>
                }
                <div className='right'>
                    {props.canDelete &&
                        <ActionBlock
                            type="pdf"
                            id={props.resource.pdf_id}
                            setIsDeleted={setIsDeleted}
                            setIsEditing={setIsEditing}
                            restoreValue={() => { setValueDesc(restoredValues.desc); setValueTitle(restoredValues.title); }}
                            content={{
                                title: valueTitle.trim(),
                                description: valueDesc.trim(),
                            }}
                        />
                    }
                    <p className='tag pdf'>PDF</p>
                </div>
            </div>
            {props.resource.description && props.resource.description !== "N/A" && isEditing
                ? <FormTextarea value={valueDesc} update={setValueDesc} class="description-input" />
                : <p className='desc'>{valueDesc}</p>
            }
            {/* <div className='icon-container'>
                <span className="material-symbols-outlined">content_copy</span>
                <span className='url'>PDF File</span>
                {selectedPdf &&
                    <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.9.179/build/pdf.worker.min.js">
                        <Viewer fileUrl={selectedPdf} />
                    </Worker>
                }
            </div> */}
        </div>
    );
}