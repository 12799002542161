import React, { useEffect, useState } from 'react';
import { ActionBlock, DeleteBlock, EditBlock } from './Blocks';
import { FormInput, FormTextarea } from '../Form/Form';
import { updateTextBlock } from '../../api/resources';
import { selectCurrentUser } from '../../features/userSlice';
import { useSelector } from 'react-redux';

export default function TextBlock(props) {
    const [isDeleted, setIsDeleted] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [valueDesc, setValueDesc] = useState(props.resource.content);
    const [valueTitle, setValueTitle] = useState(props.resource.title);
    const [restoredValues, setRestoredValues] = useState(null);

    useEffect(() => {
        if (isEditing) {
            setRestoredValues({ title: valueTitle, desc: valueDesc });
        }
    }, [isEditing]);

    if (isDeleted) {
        return null;
    }

    return (
        <div className='text-block-container block-container' key={props.resource.txt_id}>
            <div className='block-title-container'>
                <p className='title flex'>
                    <span className="material-symbols-outlined icon">sticky_note_2</span>
                    {isEditing ? <FormInput value={valueTitle} update={setValueTitle} required={true} /> : <span>{valueTitle}</span>}
                </p>
                <div className='right'>
                    {props.canDelete &&
                        <ActionBlock
                            type="text"
                            id={props.resource.txt_id}
                            setIsDeleted={setIsDeleted}
                            setIsEditing={setIsEditing}
                            restoreValue={() => { setValueDesc(restoredValues.desc); setValueTitle(restoredValues.title); }}
                            content={{
                                title: valueTitle.trim(),
                                content: valueDesc.trim(),
                                pathway_id: props.pathwayId,
                            }}
                        />
                    }
                    <p className='tag text'>Text</p>
                </div>
            </div>
            {isEditing ?
                <FormTextarea value={valueDesc} update={setValueDesc} class="description-input" />
                :
                <p>{valueDesc}</p>
            }
        </div>
    );
}