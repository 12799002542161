import React, { useContext, useState, useEffect, useRef } from 'react';
import './UploadPage.scss';
import Upload from '../../components/Upload/Upload';
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';
import { selectCurrentUser } from '../../features/userSlice';
import { isGuest } from '../../util/userLoggedIn';

export default function UploadPage() {
    const user = useSelector(selectCurrentUser);

    return (
        <>
            <Helmet>
                <title>Upload | Tecademia</title>
                <meta name='description' content='Upload learning resource, in text, url, or pdf format' />
                <meta name='keywords' content='learning resources, text, url, pdf, upload' />
            </Helmet>
            <div className={`upload-page-container ${isGuest(user) && "disabled"}`}>
                <Upload pathwayId={null} disabled={isGuest(user)} />
            </div>
        </>

    );
}

