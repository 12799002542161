import React, { useEffect } from 'react';
import './Notification.scss';

export default function Success({ message }) {
    useEffect(() => {
        const timer = setTimeout(() => {
            const element = document.querySelector('.success-container');
            if (element) {
                element.classList.add('slide-out');
            }
        }, 1000);
        return () => clearTimeout(timer);
    }, []);

    return (
        <div className={"success-container"}>
            <p>{message}</p>
        </div>
    );
}