import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';

const firebaseConfig = {
    apiKey: "AIzaSyCwlVcRUV0EVASMzmM8wXA4i97OAkTgiq4",
    authDomain: "tecademia-mvp.firebaseapp.com",
    projectId: "tecademia-mvp",
    storageBucket: "tecademia-mvp.appspot.com",
    messagingSenderId: "800290707484",
    appId: "1:800290707484:web:1917c6bb79141ad59927d9",
    measurementId: "G-4R0QBKKJQR"
};


const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);

